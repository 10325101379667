import React from 'react';
import { useDebugMode } from '../DebugContext';

const DialogBubble = ({ text, speaker, className, showDocs = false }) => {
  const isDebugMode = useDebugMode(); // Zjistíme, zda je zapnutý debug mód

  // Pokud jsme v debug módu a showDocs je true, zobrazíme pouze dokumentaci
  if (isDebugMode && showDocs) {
    return (
      <div className="mt-4 p-4 bg-gray-50 border border-gray-300 rounded-lg">
        <p>
          <strong>DialogBubble</strong> slouží k zobrazení dialogu mezi postavami ve hře. Můžete zadat text dialogu a volitelně jméno mluvčího (speaker), které se zobrazí nad dialogem.
        </p>
        <div className="flex items-start">
          <div className="mr-4">
            <div className="text-lg font-bold text-gray-800 mb-2 px-2">
              Nepřítel
            </div>
          </div>
          <div className="relative bg-gray-200 text-gray-900 font-medium p-4 rounded-lg shadow-lg max-w-lg border-2 border-gray-400">
            <p>Bojíš se mě? Měl bys!</p>
          </div>
        </div>
        <pre className="bg-gray-100 p-4 rounded mt-4 overflow-x-auto max-w-full">
{`<DialogBubble text="Bojíš se mě? Měl bys!" speaker="Nepřítel" />`}
        </pre>
      </div>
    );
  }

  // Normální funkční komponenta
  return (
    <div className={`${className} mb-4`}>
      <div className="flex items-start">
        <div className="mr-4">
          {speaker && (
            <div className="text-lg font-bold text-gray-800 mb-2 px-2">
              {speaker}
            </div>
          )}
        </div>
        <div className="relative bg-gray-200 text-gray-900 font-medium p-4 rounded-lg shadow-lg max-w-lg border-2 border-gray-400">
          <p>{text}</p>
        </div>
      </div>
    </div>
  );
};

export default DialogBubble;
