import React from 'react';
import { Link } from 'react-router-dom';

const ThankYou = () => {
  return (
    <div className="max-w-4xl mx-auto p-6 bg-white shadow-md rounded-lg mt-8 text-center">
      <h1 className="text-4xl font-bold text-gray-800 mb-6">Děkujeme za odehrání hry!</h1>
      <p className="text-gray-600 mb-6">
        Doufáme, že jste si hru užili a že vám přinesla nejen zábavu, ale také nový pohled na naše interaktivní dobrodružství.
        Vaše zážitky nás motivují k tomu, abychom neustále vytvářeli nové výzvy a příběhy, které osloví všechny zvídavé hráče.
      </p>

      <h2 className="text-3xl font-semibold text-gray-800 mb-4">Zahrajte si další hru!</h2>
      <p className="text-gray-600 mb-6">
        Naše nabídka her neustále roste a přináší nové příběhy a hádanky, které čekají na vaše objevování. Vyzkoušejte další
        hry a objevte nové světy plné napětí a zábavy. Každá hra je unikátní a přináší vám možnost ponořit se do zcela
        nového dobrodružství.
      </p>

      <h2 className="text-3xl font-semibold text-gray-800 mb-4">Sdílejte svůj zážitek</h2>
      <p className="text-gray-600 mb-6">
        Rádi bychom slyšeli váš názor! Podělte se o své zkušenosti s přáteli a rodinou a dejte nám vědět, co se vám líbilo.
        Vaše zpětná vazba nám pomáhá zlepšovat a přinášet ještě lepší zážitky pro všechny.
      </p>

      <Link
        to="/"
        className="inline-block mt-8 px-10 py-4 text-xl bg-green-600 text-white font-bold rounded-lg shadow-lg hover:bg-green-700 transition-all duration-300 ease-in-out transform hover:scale-105"
      >
        Zpět na domovskou stránku
      </Link>
    </div>
  );
};

export default ThankYou;
