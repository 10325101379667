import React from 'react';
import { useDebugMode } from '..//DebugContext';

const SmallTextElement = ({ text, className, showDocs = false }) => {
  const isDebugMode = useDebugMode(); // Zjistíme, zda je zapnutý debug mód

  // Pokud jsme v debug módu a showDocs je true, zobrazíme pouze dokumentaci
  if (isDebugMode && showDocs) {
    return (
      <div className="mt-4 p-4 bg-gray-50 border border-gray-300 rounded-lg">
        <p>
          <strong>SmallTextElement</strong> slouží k zobrazení menšího textu. Tento prvek se často používá pro zobrazování sekundárních informací, poznámek nebo textů, které mají menší důležitost.
        </p>
        <div className="text-sm text-gray-500 text-left my-2">
          Toto je příklad menšího textu.
        </div>
        <pre className="bg-gray-100 p-4 rounded mt-4 overflow-x-auto max-w-full">
{`<SmallTextElement text="Toto je příklad menšího textu." />`}
        </pre>
      </div>
    );
  }

  // Normální funkční komponenta
  return (
    <div className={`text-sm text-gray-500 text-left my-2 ${className}`}>
      <p>{text}</p>
    </div>
  );
};

export default SmallTextElement;
