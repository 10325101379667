import React, { useEffect, useState } from 'react';
import { useDebugMode } from '../DebugContext';

const FlashMessage = ({ type, message, duration = 3000, onClose, showDocs = false }) => {
  const [visible, setVisible] = useState(true);
  const isDebugMode = useDebugMode(); // Zjistíme, zda je zapnutý debug mód

  useEffect(() => {
    if (!isDebugMode) {
      const timer = setTimeout(() => {
        setVisible(false);
        onClose();
      }, duration);

      return () => clearTimeout(timer);
    }
  }, [duration, onClose, isDebugMode]);

  if (!visible && !isDebugMode) return null;

  const getClasses = () => {
    switch (type) {
      case 'info':
        return 'bg-blue-100 text-blue-800 border-blue-500';
      case 'warning':
        return 'bg-yellow-100 text-yellow-800 border-yellow-500';
      case 'error':
        return 'bg-red-100 text-red-800 border-red-500';
      default:
        return 'bg-gray-100 text-gray-800 border-gray-400';
    }
  };

  // Pokud jsme v debug módu a showDocs je true, zobrazíme pouze dokumentaci
  if (isDebugMode && showDocs) {
    return (
      <div className="mt-4 p-4 bg-gray-50 border border-gray-300 rounded-lg">
        <p>
          <strong>FlashMessage</strong> slouží k zobrazení krátkých informativních zpráv, jako jsou upozornění, varování nebo chyby. Typ zprávy se určuje pomocí prop <code>type</code> (např. "info", "warning", "error").
        </p>
        <p>
          Níže jsou příklady tří typů zpráv, které komponenta umí zobrazit:
        </p>
        <div className="space-y-4">
          <div className="p-4 border-l-4 bg-blue-100 text-blue-800 border-blue-500 rounded-lg shadow-md">
            Info zpráva: "Toto je informační zpráva."
          </div>
          <div className="p-4 border-l-4 bg-yellow-100 text-yellow-800 border-yellow-500 rounded-lg shadow-md">
            Warning zpráva: "Toto je varovná zpráva."
          </div>
          <div className="p-4 border-l-4 bg-red-100 text-red-800 border-red-500 rounded-lg shadow-md">
            Error zpráva: "Toto je chybová zpráva."
          </div>
        </div>
        <pre className="bg-gray-100 p-4 rounded mt-4 overflow-x-auto max-w-full">
{`<FlashMessage type="info" message="Toto je informační zpráva." onClose={() => console.log('Zpráva zavřena')} />
<FlashMessage type="warning" message="Toto je varovná zpráva." onClose={() => console.log('Zpráva zavřena')} />
<FlashMessage type="error" message="Toto je chybová zpráva." onClose={() => console.log('Zpráva zavřena')} />`}
        </pre>
      </div>
    );
  }

  // Normální funkční komponenta
  return (
    <div className="mb-4">
      <div className={`p-4 border-l-4 rounded-lg shadow-md mb-4 max-w-xs ${getClasses()}`}>
        {message}
      </div>
    </div>
  );
};

export default FlashMessage;
