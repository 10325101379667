import React from 'react';
import { useDebugMode } from '../DebugContext';

const QuoteElement = ({ text, className, showDocs = false }) => {
  const isDebugMode = useDebugMode(); // Zjistíme, zda je zapnutý debug mód

  // Pokud jsme v debug módu a showDocs je true, zobrazíme pouze dokumentaci
  if (isDebugMode && showDocs) {
    return (
      <div className="mt-4 p-4 bg-gray-50 border border-gray-300 rounded-lg">
        <p>
          <strong>QuoteElement</strong> slouží k zobrazení citací. Text je stylizován kurzívou s levým okrajem a je často používán pro zvýraznění důležitých výroků nebo citací ve hře.
        </p>
        <div className="text-gray-500 border-l-4 border-gray-400 pl-4 italic my-4 text-center">
          "Toto je ukázková citace."
        </div>
        <pre className="bg-gray-100 p-4 rounded mt-4 overflow-x-auto max-w-full">
{`<QuoteElement text="Toto je ukázková citace." />`}
        </pre>
      </div>
    );
  }

  // Normální funkční komponenta
  return (
    <div className={`text-gray-500 border-l-4 border-gray-400 pl-4 italic my-4 text-center ${className}`}>
      <p>{text}</p>
    </div>
  );
};

export default QuoteElement;
