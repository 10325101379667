import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const OrderForm = () => {
  const location = useLocation();

  const initialGame = location.state?.game || JSON.parse(sessionStorage.getItem('selectedGame')) || {};

  const [gameState, setGameState] = useState(initialGame);
  const [email, setEmail] = useState(sessionStorage.getItem('email') || '');
  const [phone, setPhone] = useState(sessionStorage.getItem('phone') || '');
  const [locationValue, setLocation] = useState(sessionStorage.getItem('location') || '');
  const [gdprConsent, setGdprConsent] = useState(sessionStorage.getItem('gdprConsent') === 'true');
  const [termsConsent, setTermsConsent] = useState(sessionStorage.getItem('termsConsent') === 'true');
  const [error, setError] = useState(null);
  const regions = gameState?.regions || [];

  useEffect(() => {
    if (location.state?.game) {
      setGameState(location.state.game);
      sessionStorage.setItem('selectedGame', JSON.stringify(location.state.game)); // Uloží aktuální hru včetně regions
    } else if (sessionStorage.getItem('selectedGame')) {
      const storedGame = JSON.parse(sessionStorage.getItem('selectedGame'));
      setGameState(storedGame);
    }
  }, [location.state]);

  useEffect(() => {
    sessionStorage.setItem('email', email);
    sessionStorage.setItem('phone', phone);
    sessionStorage.setItem('location', locationValue);
    sessionStorage.setItem('gdprConsent', gdprConsent);
    sessionStorage.setItem('termsConsent', termsConsent);
  }, [email, phone, locationValue, gdprConsent, termsConsent]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setError(null);

    if (!termsConsent || !gdprConsent) {
      setError('Musíte souhlasit s obchodními podmínkami a se zpracováním osobních údajů.');
      return;
    }
    if (!locationValue) {
      setError('Vyberte prosím lokalitu.');
      return;
    }

    try {
      const formData = new FormData();
      formData.append('email', email);
      formData.append('phone', phone);
      formData.append('location', locationValue);
      formData.append('agree_terms', termsConsent);
      formData.append('agree_gdpr', gdprConsent);
      formData.append('game_name', gameState?.name);

      const response = await fetch('/create_order', {
        method: 'POST',
        body: formData,
      });

      const data = await response.json();

      if (response.ok) {
        window.location.href = data.redirectUrl;
      } else {
        setError(data.detail || 'Nastala chyba při odesílání objednávky.');
      }
    } catch (error) {
      console.error('Chyba:', error);
      setError('Nepodařilo se odeslat objednávku. Zkuste to prosím znovu později.');
    }
  };

  return (
      <div className="max-w-4xl mx-auto p-6 bg-white shadow-md rounded-lg mt-8">
        <h1 className="text-4xl font-bold text-gray-800 mb-4">Objednávka hry "{gameState?.title}"</h1>

        <p className="text-gray-600 font-semibold mb-6">
          Cena hry: {gameState?.price ? `${gameState.price} Kč` : 'Cena není dostupná'}
        </p>

        <p className="text-gray-600 mb-6">
          Objednáváte si přístup do hry "{gameState?.title}" – interaktivního zážitku, který vás vtáhne do napínavého
          příběhu a odhalí vám skryté tajemství. Vyberte lokalitu, kde chcete hru hrát, a zadejte své kontaktní údaje
          pro dokončení objednávky.
        </p>

        <form onSubmit={handleSubmit} className="space-y-4">

          <div>
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="location">
              Vyberte lokalitu <span className="text-red-500">*</span>
            </label>
            <select
                id="location"
                required
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                value={locationValue}
                onChange={(e) => setLocation(e.target.value)}
            >
              <option value="">-- Vyberte lokalitu --</option>
              {regions.map((loc) => (
                  <option key={loc.name} value={loc.name}>
                    {loc.description}
                  </option>
              ))}
            </select>
          </div>

          <div>
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="email">
              E-mail <span className="text-red-500">*</span>
            </label>
            <input
                type="email"
                id="email"
                required
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
            />
          </div>

          <div>
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="phone">
              Telefon (nepovinné)
            </label>
            <input
                type="tel"
                id="phone"
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
            />
            <p className="text-gray-500 text-xs mt-1">Uveďte své telefonní číslo, pokud chcete obdržet notifikace.</p>
          </div>

          <div className="mt-4">
            <label className="inline-flex items-center">
              <input
                  type="checkbox"
                  checked={termsConsent}
                  onChange={() => setTermsConsent(!termsConsent)}
                  className="form-checkbox text-blue-600"
              />
              <span className="ml-2 text-gray-700 text-sm">
              Souhlasím s <a href="/terms" className="text-blue-600 underline">obchodními podmínkami</a>.
            </span>
            </label>
          </div>

          <div className="mt-4">
            <label className="inline-flex items-center">
              <input
                  type="checkbox"
                  checked={gdprConsent}
                  onChange={() => setGdprConsent(!gdprConsent)}
                  className="form-checkbox text-blue-600"
              />
              <span className="ml-2 text-gray-700 text-sm">
              Souhlasím se zpracováním osobních údajů v souladu s <a href="/policy" className="text-blue-600 underline">zásadami ochrany osobních údajů</a>.
            </span>
            </label>
          </div>

          {error && <p className="text-red-500 text-sm">{error}</p>}

          <div className="mt-6">
            <button
                type="submit"
                className="w-full bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            >
              Objednat
            </button>
          </div>
        </form>
      </div>
  );
};

export default OrderForm;
