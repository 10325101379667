import React from 'react';
import { useDebugMode } from '../DebugContext';

const Narrative = ({ text, className = '', showDocs = false }) => {
  const isDebugMode = useDebugMode(); // Zjistíme, zda je zapnutý debug mód

  // Pokud jsme v debug módu a showDocs je true, zobrazíme pouze dokumentaci
  if (isDebugMode && showDocs) {
    return (
      <div className="mt-4 p-4 bg-gray-50 border border-gray-300 rounded-lg">
        <p>
          <strong>Narrative</strong> slouží k zobrazení textu, který představuje příběh nebo narativ ve hře. Text je stylizován kurzívou a často se používá pro vyprávění událostí nebo popis prostředí.
        </p>
        <p className="text-gray-700 italic text-center my-4">
          Ukázkový příběh: "Slunce pomalu zapadalo za horizontem a město začalo zalévat zlatavé světlo."
        </p>
        <pre className="bg-gray-100 p-4 rounded mt-4 overflow-x-auto max-w-full">
{`<Narrative text="Slunce pomalu zapadalo za horizontem a město začalo zalévat zlatavé světlo." />`}
        </pre>
      </div>
    );
  }

  // Normální funkční komponenta
  return (
    <p className={`text-gray-700 italic text-center my-4 ${className}`}>
      {text}
    </p>
  );
};

export default Narrative;
