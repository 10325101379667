import React, { createContext, useContext } from 'react';

// Vytvoření kontextu pro debug mód
const DebugContext = createContext(false);

// Provider, který zajistí, že debug mód bude dostupný všem komponentám
export const DebugProvider = ({ children, debugMode }) => {
  return (
    <DebugContext.Provider value={debugMode}>
      {children}
    </DebugContext.Provider>
  );
};

// Custom hook pro jednodušší přístup k debug módu
export const useDebugMode = () => {
  return useContext(DebugContext);
};
