import React, { useState, useEffect } from 'react';
import { FaPaperPlane } from 'react-icons/fa'; // Import ikony
import { useDebugMode } from '../DebugContext';

const InputField = ({ fieldId, label, className, fadeDuration = 1000, onComplete, sendInputValue, showDocs = false }) => {
  const [isVisible, setIsVisible] = useState(false);
  const [animationComplete, setAnimationComplete] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const isDebugMode = useDebugMode(); // Zjistíme, zda je zapnutý debug mód

  useEffect(() => {
    const fadeInTimeout = setTimeout(() => {
      setIsVisible(true);
    }, 10);

    const completeTimeout = setTimeout(() => {
      if (!animationComplete) {
        setAnimationComplete(true);
        if (onComplete) onComplete();
      }
    }, fadeDuration);

    return () => {
      clearTimeout(fadeInTimeout);
      clearTimeout(completeTimeout);
    };
  }, [fadeDuration, onComplete, animationComplete]);

  const handleSend = () => {
    if (sendInputValue) {
      sendInputValue({ user_input: inputValue, field_id: fieldId });
    }
  };

  // Pokud jsme v debug módu a showDocs je true, zobrazíme pouze dokumentaci
  if (isDebugMode && showDocs) {
    return (
      <div className="mt-4 p-4 bg-gray-50 border border-gray-300 rounded-lg">
        <p>
          <strong>InputField</strong> slouží k zadávání uživatelského vstupu, který je poté odeslán tlačítkem. Obsahuje animaci zobrazování a tlačítko s ikonou odesílání.
        </p>
        <div className="input-field max-w-full">
          <label htmlFor="sampleInput" className="block text-lg font-medium text-gray-700 mb-2">
            Zadejte odpověď:
          </label>
          <div className="relative">
            <input
              id="sampleInput"
              className="block w-full px-4 py-2 border border-gray-400 rounded-md shadow-lg transition-all focus:outline-none focus:ring-2 focus:ring-green-400 focus:border-green-400 hover:border-green-400"
              placeholder="Zadejte svou odpověď..."
            />
          </div>
          <button className="mt-4 flex items-center justify-center px-6 py-3 bg-green-500 text-white font-bold rounded-lg shadow-lg transform transition-transform hover:scale-105 hover:bg-green-600 focus:outline-none focus:ring-4 focus:ring-green-400 focus:ring-opacity-50">
            <FaPaperPlane className="mr-2" /> Odeslat
          </button>
        </div>
        <pre className="bg-gray-100 p-4 rounded mt-4 overflow-x-auto max-w-full">
{`<InputField fieldId="input1" label="Zadejte odpověď:" sendInputValue={handleSend} />`}
        </pre>
      </div>
    );
  }

  // Normální funkční komponenta
  return (
    <div
      id={fieldId}
      className={`input-field ${className} transition-opacity duration-${fadeDuration} ${isVisible ? 'opacity-100' : 'opacity-0'}`}
    >
      <label htmlFor={fieldId} className="block text-lg font-medium text-gray-700 mb-2">
        {label}
      </label>
      <div className="relative">
        <input
          id={fieldId}
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          className="block w-full px-4 py-2 border border-gray-400 rounded-md shadow-lg transition-all focus:outline-none focus:ring-2 focus:ring-green-400 focus:border-green-400 hover:border-green-400"
          placeholder="Zadejte svou odpověď..."
        />
      </div>
      <button
        onClick={handleSend}
        className="mt-4 flex items-center justify-center px-6 py-3 bg-green-500 text-white font-bold rounded-lg shadow-lg transform transition-transform hover:scale-105 hover:bg-green-600 focus:outline-none focus:ring-4 focus:ring-green-400 focus:ring-opacity-50"
      >
        <FaPaperPlane className="mr-2" /> Odeslat
      </button>
    </div>
  );
};

export default InputField;
