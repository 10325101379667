import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';


const TermsAndConditions = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const game = location.state?.game;

  const handleBackToOrder = () => {
    // Uložení dat hry do sessionStorage
    if (game) {
      sessionStorage.setItem('selectedGame', JSON.stringify(game));
    }
    navigate("/order", { state: { game } });
  };

  return (
      <div className="max-w-4xl mx-auto p-6 bg-white shadow-md rounded-lg mt-8">
          <h1 className="text-4xl font-bold text-gray-800 mb-4">Obchodní podmínky</h1>

          <p className="text-gray-600 mb-4">
              Tyto obchodní podmínky upravují podmínky prodeje interaktivních her a jsou závazné pro všechny
              uživatele této služby. Zakoupením a používáním této služby souhlasíte s následujícími podmínkami.
          </p>

          <h2 className="text-2xl font-semibold text-gray-800 mb-4">1. Předmět smlouvy</h2>
          <p className="text-gray-600 mb-4">
              Předmětem smlouvy je poskytnutí přístupu k interaktivní hře "Poslední Čarodějnice" po úspěšném zaplacení
              objednávky.
          </p>

          <h2 className="text-2xl font-semibold text-gray-800 mb-4">2. Cena a platba</h2>
          <p className="text-gray-600 mb-4">
              Cena služby je stanovena na základě aktuální nabídky a je splatná před zpřístupněním hry. Platbu je možné
              provést bankovním převodem dle poskytnutých údajů.
          </p>

          <h2 className="text-2xl font-semibold text-gray-800 mb-4">3. Dodací podmínky</h2>
          <p className="text-gray-600 mb-4">
              Po úspěšné platbě obdržíte přístupový kód k interaktivní hře. Přístup k této hře bude poskytnut
              prostřednictvím emailu,
              který jste zadali při objednávce.
          </p>

          <h2 className="text-2xl font-semibold text-gray-800 mb-4">4. Práva a povinnosti uživatele</h2>
          <p className="text-gray-600 mb-4">
              Uživatel je povinen používat službu v souladu s podmínkami a nevytvářet neautorizované kopie nebo sdílet
              přístup k hře s jinými osobami.
          </p>

          <h2 className="text-2xl font-semibold text-gray-800 mb-4">5. Práva a povinnosti poskytovatele</h2>
          <p className="text-gray-600 mb-4">
              Poskytovatel si vyhrazuje právo měnit obsah služby a její funkcionalitu, pokud to bude potřebné pro
              technické nebo provozní důvody.
          </p>

          <h2 className="text-2xl font-semibold text-gray-800 mb-4">6. Ochrana osobních údajů</h2>
          <p className="text-gray-600 mb-4">
              Poskytovatel se zavazuje chránit veškeré osobní údaje v souladu s platnými zákony o ochraně osobních
              údajů. Další informace o ochraně osobních údajů naleznete v našich zásadách ochrany osobních údajů.
          </p>

          <h2 className="text-2xl font-semibold text-gray-800 mb-4">7. Reklamační řád</h2>
          <p className="text-gray-600 mb-6">
              Reklamace lze uplatnit v případě, že služba nebyla poskytnuta v souladu s podmínkami. Reklamace bude
              vyřízena do 30 dnů od obdržení.
          </p>

          <div className="mt-8">
              <button
                  onClick={handleBackToOrder}
                  className="text-blue-500 hover:text-blue-700 font-semibold underline"
              >
                  Zpět na objednávkový formulář
              </button>
          </div>
      </div>
  );
};

export default TermsAndConditions;
