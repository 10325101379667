import React from 'react';
import { useDebugMode } from '../DebugContext';

const Subtitle = ({ text, className = '', showDocs = false }) => {
  const isDebugMode = useDebugMode(); // Zjistíme, zda je zapnutý debug mód

  // Pokud jsme v debug módu a showDocs je true, zobrazíme pouze dokumentaci
  if (isDebugMode && showDocs) {
    return (
      <div className="mt-4 p-4 bg-gray-50 border border-gray-300 rounded-lg">
        <p>
          <strong>Subtitle</strong> slouží k zobrazení podtitulků, obvykle pod hlavním nadpisem. Tento text je o něco větší než normální text a je stylizován jako nadpis nižší úrovně.
        </p>
        <h2 className="text-2xl font-semibold text-gray-600 text-center mb-4">
          Toto je příklad podtitulu.
        </h2>
        <pre className="bg-gray-100 p-4 rounded mt-4 overflow-x-auto max-w-full">
{`<Subtitle text="Toto je příklad podtitulu." />`}
        </pre>
      </div>
    );
  }

  // Normální funkční komponenta
  return (
    <h2 className={`text-2xl font-semibold text-gray-600 text-center mb-4 ${className}`}>
      {text}
    </h2>
  );
};

export default Subtitle;
