import React from 'react';
import { useDebugMode } from '../DebugContext';

const WarningElement = ({ text, className, showDocs = false }) => {
  const isDebugMode = useDebugMode(); // Zjistíme, zda je zapnutý debug mód

  // Pokud jsme v debug módu a showDocs je true, zobrazíme pouze dokumentaci
  if (isDebugMode && showDocs) {
    return (
      <div className="mt-4 p-4 bg-gray-50 border border-gray-300 rounded-lg">
        <p>
          <strong>WarningElement</strong> slouží k zobrazení varovných zpráv. Tento prvek má výrazné červené pozadí s animací pulsování a používá se k upozornění uživatele na důležité nebo nebezpečné situace.
        </p>
        <div className="relative text-white bg-red-600 border-2 border-yellow-400 px-4 py-2 rounded-lg shadow-lg text-center font-bold uppercase tracking-wide animate-pulse">
          <p className="text-xl">Toto je varovná zpráva</p>
          <div className="absolute inset-0 border-4 border-red-800 rounded-lg opacity-50"></div>
        </div>
        <pre className="bg-gray-100 p-4 rounded mt-4 overflow-x-auto max-w-full">
{`<WarningElement text="Toto je varovná zpráva" />`}
        </pre>
      </div>
    );
  }

  // Normální funkční komponenta
  return (
    <div className={`relative text-white bg-red-600 border-2 border-yellow-400 px-4 py-2 rounded-lg shadow-lg text-center font-bold uppercase tracking-wide animate-pulse ${className}`}>
      <p className="text-xl">{text}</p>
      <div className="absolute inset-0 border-4 border-red-800 rounded-lg opacity-50"></div>
    </div>
  );
};

export default WarningElement;
