import React from 'react';
import { useDebugMode } from '../DebugContext';

const SuccessElement = ({ text, className, showDocs = false }) => {
  const isDebugMode = useDebugMode(); // Zjistíme, zda je zapnutý debug mód

  // Pokud jsme v debug módu a showDocs je true, zobrazíme pouze dokumentaci
  if (isDebugMode && showDocs) {
    return (
      <div className="mt-4 p-4 bg-gray-50 border border-gray-300 rounded-lg">
        <p>
          <strong>SuccessElement</strong> slouží k zobrazení zpráv o úspěchu. Tento prvek používá zelené pozadí s animací pulsování a je ideální pro upozornění uživatele na úspěšné dokončení akce.
        </p>
        <div className="relative text-white bg-green-500 border-2 border-green-700 px-4 py-2 rounded-lg shadow-xl text-center font-bold uppercase tracking-wide">
          <p className="text-lg">Úspěch! Operace byla úspěšná.</p>
          <div className="absolute inset-0 border-4 border-green-800 rounded-lg opacity-70 animate-pulse"></div>
        </div>
        <pre className="bg-gray-100 p-4 rounded mt-4 overflow-x-auto max-w-full">
{`<SuccessElement text="Úspěch! Operace byla úspěšná." />`}
        </pre>
      </div>
    );
  }

  // Normální funkční komponenta
  return (
    <div className={`relative text-white bg-green-500 border-2 border-green-700 px-4 py-2 rounded-lg shadow-xl text-center font-bold uppercase tracking-wide ${className}`}>
      <p className="text-lg">{text}</p>
      <div className="absolute inset-0 border-4 border-green-800 rounded-lg opacity-70 animate-pulse"></div>
    </div>
  );
};

export default SuccessElement;
