import React from 'react';
import { useDebugMode } from '../DebugContext';

const InteractionElement = ({ text, className, showDocs = false }) => {
  const isDebugMode = useDebugMode(); // Zjistíme, zda je zapnutý debug mód

  // Pokud jsme v debug módu a showDocs je true, zobrazíme pouze dokumentaci
  if (isDebugMode && showDocs) {
    return (
      <div className="mt-4 p-4 bg-gray-50 border border-gray-300 rounded-lg">
        <p>
          <strong>InteractionElement</strong> slouží k zobrazení interaktivního textu, který lze kliknout. Tento text obvykle upozorňuje na akce, které může uživatel provést.
        </p>
        <div className="cursor-pointer text-blue-500 hover:text-blue-700 underline text-center my-4">
          Interaktivní text
        </div>
        <pre className="bg-gray-100 p-4 rounded mt-4 overflow-x-auto max-w-full">
{`<InteractionElement text="Klikněte zde pro více informací" />`}
        </pre>
      </div>
    );
  }

  // Normální funkční komponenta
  return (
    <div className={`cursor-pointer text-blue-500 hover:text-blue-700 underline text-center my-4 ${className}`}>
      <p>{text}</p>
    </div>
  );
};

export default InteractionElement;
