import React from 'react';
import { useDebugMode } from '../DebugContext';

const Title = ({ text, className = '', showDocs = false }) => {
  const isDebugMode = useDebugMode(); // Zjistíme, zda je zapnutý debug mód

  // Pokud jsme v debug módu a showDocs je true, zobrazíme pouze dokumentaci
  if (isDebugMode && showDocs) {
    return (
      <div className="mt-4 p-4 bg-gray-50 border border-gray-300 rounded-lg">
        <p>
          <strong>Title</strong> slouží k zobrazení hlavního nadpisu na stránce nebo v aplikaci. Tento prvek je stylizován jako velký nadpis s tučným písmem a je vhodný pro zvýraznění důležitých částí.
        </p>
        <h1 className="text-4xl font-bold text-gray-800 text-center mb-6">
          Toto je příklad hlavního nadpisu
        </h1>
        <pre className="bg-gray-100 p-4 rounded mt-4 overflow-x-auto max-w-full">
{`<Title text="Toto je příklad hlavního nadpisu" />`}
        </pre>
      </div>
    );
  }

  // Normální funkční komponenta
  return (
    <h1 className={`text-4xl font-bold text-gray-800 text-center mb-6 ${className}`}>
      {text}
    </h1>
  );
};

export default Title;
