import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import { DebugProvider } from './DebugContext';
import Home from './pages/Home';
import Game from './pages/Game';
import Payment from './pages/Payment';
import Leaderboard from './pages/Leaderboard';
import Leaderboards from './pages/Leaderboards';
import Registration from './pages/Registration';
import CodeEntry from './pages/CodeEntry';
import About from './pages/About';
import './index.css';
import Doc from "./pages/Doc";
import ThankYou from "./pages/ThankYou";
import Order from './pages/Order';
import TermsAndConditions from "./pages/TermsAndConditions";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import OrderConfirmation from "./pages/OrderConfirmation";
import TestDevice from "./pages/TestDevice";

const App = () => {
  const [isOpen, setIsOpen] = useState(false);

  // Nastavení debug módu z environmentální proměnné
  const debugMode = process.env.REACT_APP_DEBUG_MODE === 'true';

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (isOpen && !event.target.closest('.mobile-menu') && !event.target.closest('.hamburger')) {
        setIsOpen(false);
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [isOpen]);

  return (
    <DebugProvider debugMode={debugMode}>
      <Router>
        <div>
          {/* Společné menu */}
          <nav className="bg-gray-800 p-4">
            {/* Hamburger ikonka */}
            <div className="flex justify-between items-center">
              <div className="text-white text-lg font-bold">GeoGames</div>
              <div className="lg:hidden">
                <button className="hamburger flex flex-col justify-between h-6 w-6 focus:outline-none" onClick={toggleMenu}>
                  <span className="block h-0.5 w-full bg-white"></span>
                  <span className="block h-0.5 w-full bg-white"></span>
                  <span className="block h-0.5 w-full bg-white"></span>
                </button>
              </div>

              {/* Desktop menu */}
              <ul className="hidden lg:flex space-x-6 text-white">
                <li><Link to="/" className="hover:text-gray-400">Hlavní stránka</Link></li>
                {/*<li><Link to="/leaderboards" className="hover:text-gray-400">Leaderboards</Link></li>*/}
                <li><Link to="/test_device" className="hover:text-gray-400">Test zařízení</Link></li>
                <li><Link to="/about" className="hover:text-gray-400">O nás</Link></li>
                {/*<li><Link to="/register" className="hover:text-gray-400">Login/Register</Link></li>*/}
                {debugMode && <li><Link to="/doc" className="hover:text-gray-400">Dokumentace</Link></li>}
              </ul>
            </div>

            {/* Mobile menu */}
            <ul className={`lg:hidden mt-4 space-y-2 ${isOpen ? 'block' : 'hidden'} mobile-menu text-white`}>
              <li><Link to="/" className="block p-2 bg-gray-700 rounded hover:bg-gray-600">Hlavní stránka</Link></li>
              {/*<li><Link to="/leaderboards"*/}
              {/*          className="block p-2 bg-gray-700 rounded hover:bg-gray-600">Leaderboards</Link></li>*/}
              <li><Link to="/test_device" className="block p-2 bg-gray-700 rounded hover:bg-gray-600">Test zařízení</Link></li>
              <li><Link to="/about" className="block p-2 bg-gray-700 rounded hover:bg-gray-600">O nás</Link></li>
              {/*<li><Link to="/register" className="block p-2 bg-gray-700 rounded hover:bg-gray-600">Login/Register</Link></li>*/}
              {debugMode &&
                  <li><Link to="/doc" className="block p-2 bg-gray-700 rounded hover:bg-gray-600">Dokumentace</Link>
                  </li>}
            </ul>
          </nav>

          {/* Definice jednotlivých cest (stránek) */}
          <div className="container mx-auto p-4">
            <Routes>
              <Route path="/" element={<Home/>}/>
              <Route path="/game/:gameId" element={<Game/>}/>
              <Route path="/game/:gameId/:roomId" element={<Game />} />
              <Route path="/payment" element={<Payment/>}/>
              <Route path="/leaderboards" element={<Leaderboards/>}/>
              <Route path="/leaderboard" element={<Leaderboard/>}/>
              <Route path="/register" element={<Registration/>}/>
              <Route path="/codeentry" element={<CodeEntry/>}/>
              <Route path="/about" element={<About/>}/>
              <Route path="/thank_you" element={<ThankYou/>}/>
              <Route path="/home" element={<Home/>}/>
              <Route path="/order" element={<Order/>}/>
              <Route path="/terms" element={<TermsAndConditions/>}/>
              <Route path="/policy" element={<PrivacyPolicy/>}/>
              <Route path="/order_confirm" element={<OrderConfirmation/>}/>
              <Route path="/test_device" element={<TestDevice/>}/>
              {debugMode && <Route path="/doc" element={<Doc/>}/>}
            </Routes>
          </div>
        </div>
      </Router>
    </DebugProvider>
  );
};

export default App;
