import React from 'react';
import { Link } from "react-router-dom";

const Leaderboards = () => {
  return (
    <div className="max-w-4xl mx-auto p-6 bg-white shadow-md rounded-lg mt-8">
      <h1 className="text-4xl font-bold text-gray-800 mb-6">Leaderboards</h1>
      <div>
        <h2 className="text-3xl font-semibold text-gray-800 mb-4">Vyberte si hru</h2>
        <ul className="space-y-4">
          <li>
            <Link to="/leaderboard" className="block p-4 bg-blue-500 text-white rounded-lg text-center hover:bg-blue-600">
              Hra č. 1
            </Link>
          </li>
          <li>
            <Link to="/leaderboard" className="block p-4 bg-green-500 text-white rounded-lg text-center hover:bg-green-600">
              Hra č. 2
            </Link>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Leaderboards;
