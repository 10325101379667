import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const CodeEntry = () => {
  const [code, setCode] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');

    try {
      const response = await fetch('/verify-code', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ code }),
      });

      if (!response.ok) {
        throw new Error('Invalid code');
      }

      const result = await response.json();
      navigate(`/game/${result.gameId}`);
    } catch (err) {
      setError('Nesprávný kód. Zkuste to znovu.');
    }
  };

  return (
    <div className="max-w-md mx-auto p-6 bg-white shadow-md rounded-lg mt-8">
      <h2 className="text-3xl font-bold text-gray-800 mb-4 text-center">Zadejte kód hry</h2>
      <p className="text-gray-600 mb-6 text-center">Pro spuštění hry prosím zadejte kód, který jste obdrželi při objednání.</p>
      <form onSubmit={handleSubmit} className="space-y-4">
        <input
          type="text"
          className="w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
          value={code}
          onChange={(e) => setCode(e.target.value)}
          placeholder="Zadejte kód"
        />
        {error && <p className="text-red-500 text-sm">{error}</p>}
        <button
          type="submit"
          className="w-full py-2 px-4 bg-blue-500 text-white font-semibold rounded-md shadow hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
        >
          Spustit hru
        </button>
      </form>
    </div>
  );
};

export default CodeEntry;
