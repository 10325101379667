import React from 'react';
import { useDebugMode } from '../DebugContext';

const InfoElement = ({ text, className, showDocs = false }) => {
  const isDebugMode = useDebugMode(); // Zjistíme, zda je zapnutý debug mód

  // Pokud jsme v debug módu a showDocs je true, zobrazíme pouze dokumentaci
  if (isDebugMode && showDocs) {
    return (
      <div className="mt-4 p-4 bg-gray-50 border border-gray-300 rounded-lg">
        <p>
          <strong>InfoElement</strong> slouží k zobrazení informativních zpráv s výrazným modrým pozadím a obrysovou animací. Tento prvek je vhodný pro zobrazení důležitých informací nebo upozornění.
        </p>
        <div className="relative text-white bg-blue-500 border-2 border-blue-700 px-4 py-2 rounded-lg shadow-md text-center font-semibold">
          <p className="text-lg">Toto je informační zpráva.</p>
          <div className="absolute inset-0 border-4 border-blue-800 rounded-lg opacity-50"></div>
        </div>
        <pre className="bg-gray-100 p-4 rounded mt-4 overflow-x-auto max-w-full">
{`<InfoElement text="Toto je informační zpráva." />`}
        </pre>
      </div>
    );
  }

  // Normální funkční komponenta
  return (
    <div className={`relative text-white bg-blue-500 border-2 border-blue-700 px-4 py-2 rounded-lg shadow-md text-center font-semibold ${className}`}>
      <p className="text-lg">{text}</p>
      <div className="absolute inset-0 border-4 border-blue-800 rounded-lg opacity-50"></div>
    </div>
  );
};

export default InfoElement;
