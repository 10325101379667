import React from 'react';
import {Link, useLocation, useNavigate} from 'react-router-dom';

const PrivacyPolicy = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const game = location.state?.game;

  const handleBackToOrder = () => {
    // Uložení dat hry do sessionStorage
    if (game) {
      sessionStorage.setItem('selectedGame', JSON.stringify(game));
    }
    navigate("/order", { state: { game } });
  };

  return (
    <div className="max-w-4xl mx-auto p-6 bg-white shadow-md rounded-lg mt-8">
      <h1 className="text-4xl font-bold text-gray-800 mb-4">Zásady ochrany osobních údajů</h1>

      <p className="text-gray-600 mb-4">
        Tyto zásady ochrany osobních údajů stanoví, jak jsou shromažďovány, uchovávány a používány osobní údaje
        uživatelů při objednávání a hraní našich interaktivních her. Dodržujeme veškeré právní předpisy o ochraně osobních údajů, včetně GDPR.
      </p>

      <h2 className="text-2xl font-semibold text-gray-800 mb-4">1. Shromažďované osobní údaje</h2>
      <p className="text-gray-600 mb-4">
        Při objednání hry shromažďujeme následující údaje: jméno, e-mailovou adresu a případně telefonní číslo, pokud jej uživatel poskytne.
      </p>

      <h2 className="text-2xl font-semibold text-gray-800 mb-4">2. Účel zpracování osobních údajů</h2>
      <p className="text-gray-600 mb-4">
        Vaše osobní údaje zpracováváme za účelem správy vaší objednávky, komunikace a umožnění přístupu k zakoupeným hrám. Telefonní číslo je nepovinné a slouží k rychlejší komunikaci v případě problémů s objednávkou.
      </p>

      <h2 className="text-2xl font-semibold text-gray-800 mb-4">3. Právní základ zpracování</h2>
      <p className="text-gray-600 mb-4">
        Zpracováváme vaše osobní údaje na základě nezbytnosti pro splnění smlouvy (tj. poskytnutí přístupu k objednané hře) a na základě vašeho souhlasu, pokud jej udělíte pro marketingové účely.
      </p>

      <h2 className="text-2xl font-semibold text-gray-800 mb-4">4. Uchovávání osobních údajů</h2>
      <p className="text-gray-600 mb-4">
        Osobní údaje uchováváme pouze po dobu nezbytnou k plnění našich závazků vůči vám a v souladu s platnými právními předpisy.
      </p>

      <h2 className="text-2xl font-semibold text-gray-800 mb-4">5. Práva uživatele</h2>
      <p className="text-gray-600 mb-4">
        Máte právo na přístup k osobním údajům, jejich opravu nebo vymazání, omezení zpracování a přenositelnost údajů. Pro uplatnění těchto práv nás kontaktujte na uvedeném e-mailu.
      </p>

      <h2 className="text-2xl font-semibold text-gray-800 mb-4">6. Sdílení osobních údajů</h2>
      <p className="text-gray-600 mb-4">
        Vaše údaje nesdílíme s třetími stranami, pokud k tomu nemáme váš výslovný souhlas nebo pokud to nevyžadují právní předpisy.
      </p>

      <h2 className="text-2xl font-semibold text-gray-800 mb-4">7. Bezpečnost</h2>
      <p className="text-gray-600 mb-4">
        Dbáme na bezpečnost vašich osobních údajů a přijímáme přiměřená opatření k jejich ochraně proti ztrátě, zneužití nebo neoprávněnému přístupu.
      </p>

        <div className="mt-8">
            <div className="mt-8">
                <button
                    onClick={handleBackToOrder}
                    className="text-blue-500 hover:text-blue-700 font-semibold underline"
                >
                    Zpět na objednávkový formulář
                </button>
            </div>
        </div>
    </div>
  );
};

export default PrivacyPolicy;
