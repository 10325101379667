import React, { useState, useEffect } from 'react';
import { DebugProvider } from '../DebugContext';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import TextElement from '../components/TextElement';
import ImageElement from '../components/ImageElement';
import InputField from '../components/InputField';
import Option from '../components/Option';
import ShowMap from '../components/ShowMap';
import SlidingPuzzleComponent from '../components/SlidingPuzzleComponent';
import FlashMessage from '../components/FlashMessage';
import DialogBubble from '../components/DialogBubble';
import Title from '../components/Title';
import Subtitle from '../components/Subtitle';
import Narrative from '../components/Narrative';
import CodeElement from '../components/CodeElement';
import HighlightElement from '../components/HighlightElement';
import QuoteElement from '../components/QuoteElement';
import SmallTextElement from '../components/SmallTextElement';
import InteractionElement from '../components/InteractionElement';
import WarningElement from '../components/WarningElement';
import InfoElement from '../components/InfoElement';
import SuccessElement from '../components/SuccessElement';
import FailureElement from '../components/FailureElement';
import TimerTextElement from '../components/TimerTextElement';
import FerryGameComponent from "../components/FerryGameComponent";

const Game = () => {
  const debugMode = process.env.REACT_APP_DEBUG_MODE === 'true';
  const { gameId, roomId } = useParams();
  const [flashMessages, setFlashMessages] = useState([]);  // Flash zprávy
  const [components, setComponents] = useState([]);  // Komponenty pro zobrazení
  const [socket, setSocket] = useState(null);  // Stav WebSocketu
  const [roomListVisible, setRoomListVisible] = useState(false);
  const [roomList, setRoomList] = useState([]); // Přidáme nový stav pro seznam místností
  const [token, setToken] = useState(null);

  const navigate = useNavigate();

  const sendGPS = (gpsData) => {
    if (socket && socket.readyState === WebSocket.OPEN) {
      socket.send(JSON.stringify(gpsData));
      // console.log("Sending GPS data:", gpsData);
    }
  };

  const sendUserInput = (userInput) => {
    if (socket && socket.readyState === WebSocket.OPEN) {
      socket.send(JSON.stringify(userInput));
    }
  };

  const sendInputValue = (inputData) => {
    if (socket && socket.readyState === WebSocket.OPEN) {
      socket.send(JSON.stringify(inputData));
    }
  };

  const toggleRoomList = () => {
    setRoomListVisible(!roomListVisible);
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.ctrlKey && event.key === 'r') {
        event.preventDefault();
        toggleRoomList();
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  // Připojení k WebSocket serveru při načtení komponenty
  useEffect(() => {const verifyCode = async () => {
      try {
        const response = await fetch('/verify-code', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ code: gameId })
        });
        const data = await response.json();
        console.log(data);
        if (data.success) setToken(data.token);
        else navigate("/");  // Přesměrování, pokud ověření selže
      } catch (error) {
        console.error("Verification failed:", error);
      }
    };

    // Zavoláme verifyCode pouze pokud token ještě není nastaven
    if (!token) {
      verifyCode();
    } else if (!socket) {
      const ws = new WebSocket(`wss://${window.location.host}/ws/${gameId}/${roomId}?token=${token}`);
      ws.onopen = () => console.log("Connected to WebSocket");
      ws.onmessage = (event) => {
        try {
          const parsedData = JSON.parse(event.data);
          console.log("Received data from WebSocket:", parsedData);

          if (parsedData.action === 'clear') {
            console.log("Clearing canvas...");
            setComponents([]);  // Vyprázdnění plátna
            setFlashMessages([]);  // Vyprázdnění flash zpráv
            return;  // Ukončení zpracování, protože chceme jenom vyčistit
          }

          if (parsedData.action === 'rooms') {
            console.log("Handling rooms action");
            setRoomList(parsedData.rooms);
            return;  // Ukončení zpracování, protože nechceme přidávat do komponent
          }

          if (['flash_info', 'flash_warning', 'flash_error'].includes(parsedData.action)) {
            setFlashMessages((prevMessages) => {
              // Kontrola duplicitní info hlášky
              if (!prevMessages.some(msg => msg.text === parsedData.text)) {
                return [...prevMessages, parsedData];
              }
              return prevMessages;
            });
          }

          if (parsedData.action === 'redirect' && parsedData.url) {
              navigate(parsedData.url);
              ws.close();
              return;
          }

          setComponents((prevComponents) => {
            console.log("Adding component:", parsedData, parsedData.action);
            return [...prevComponents, parsedData];
          });

        } catch (error) {
          console.error('Invalid JSON received:', error);
        }
      };
      ws.onclose = () => console.log("WebSocket connection closed");

      setSocket(ws);
    }

    return () => {
      if (socket) socket.close();
    };
  }, [token, socket, gameId, roomId, navigate]);

  const handleFlashMessageClose = (index) => {
    setFlashMessages((prevMessages) => prevMessages.filter((_, i) => i !== index));
  };

  return (
    <DebugProvider debugMode={debugMode}>
      {/*<div className="w-full p-2 bg-white shadow-md rounded-lg mt-8">*/}
      <div className="w-full bg-white mt-8">
        {/* Flash messages */}
        <div className="space-y-4">
          {flashMessages.map((msg, index) => (
              <FlashMessage
                  key={index}
                  type={msg.action}
                  message={msg.text}
                  onClose={() => handleFlashMessageClose(index)}
              />
          ))}
        </div>

        {/* Dynamicky vkládané komponenty */}
        {/*<div className="mt-8 p-2 border border-gray-300 rounded-lg bg-gray-50 space-y-6">*/}
        <div className="border-gray-300 bg-gray-50 space-y-6">
          {components.map((component, index) => {
            switch (component.action) {
              case 'text':
                return (
                    <TextElement
                        key={`${index}-${Math.random()}`}
                        value={component.value}
                        type={component.type}
                        className={component.class_name}
                    />
                );
              case 'dialogBubble':
                return (
                    <DialogBubble
                        key={`${index}-${Math.random()}`}
                        text={component.text}
                        speaker={component.speaker}
                        className={component.class_name}
                    />
                );
              case 'title':
                return (
                    <Title
                        key={`${index}-${Math.random()}`}
                        text={component.text}
                        className={component.class_name}
                    />
                );
              case 'subtitle':
                return (
                    <Subtitle
                        key={`${index}-${Math.random()}`}
                        text={component.text}
                        className={component.class_name}
                    />
                );
              case 'narrative':
                return (
                    <Narrative
                        key={`${index}-${Math.random()}`}
                        text={component.text}
                        className={component.class_name}
                    />
                );
              case 'code':
                return (
                    <CodeElement
                        key={`${index}-${Math.random()}`}
                        text={component.text}
                        className={component.class_name}
                    />
                );
              case 'highlight':
                return (
                    <HighlightElement
                        key={`${index}-${Math.random()}`}
                        text={component.text}
                        className={component.class_name}
                    />
                );
              case 'quote':
                return (
                    <QuoteElement
                        key={`${index}-${Math.random()}`}
                        text={component.text}
                        className={component.class_name}
                    />
                );
              case 'small':
                return (
                    <SmallTextElement
                        key={`${index}-${Math.random()}`}
                        text={component.text}
                        className={component.class_name}
                    />
                );
              case 'interaction':
                return (
                    <InteractionElement
                        key={`${index}-${Math.random()}`}
                        text={component.text}
                        className={component.class_name}
                    />
                );
              case 'warning':
                return (
                    <WarningElement
                        key={`${index}-${Math.random()}`}
                        text={component.text}
                        className={component.class_name}
                    />
                );
              case 'info':
                return (
                    <InfoElement
                        key={`${index}-${Math.random()}`}
                        text={component.text}
                        className={component.class_name}
                    />
                );
              case 'success':
                return (
                    <SuccessElement
                        key={`${index}-${Math.random()}`}
                        text={component.text}
                        className={component.class_name}
                    />
                );
              case 'failure':
                return (
                    <FailureElement
                        key={`${index}-${Math.random()}`}
                        text={component.text}
                        className={component.class_name}
                    />
                );
              case 'timerText':
                return (
                    <TimerTextElement
                        key={`${index}-${Math.random()}`}
                        initialTime={component.initialTime}
                        className={component.class_name}
                    />
                );
              case 'image':
                return (
                    <ImageElement
                        key={index}
                        url={component.url}
                        alt={component.alt}
                        size={component.size}
                        // className={component.class_name}
                    />
                );
              case 'input_field':
                return (
                    <InputField
                        key={component.field_id}
                        fieldId={component.field_id}
                        label={component.label}
                        className={component.class_name}
                        sendInputValue={sendInputValue}
                    />
                );
              case 'option':
                return (
                    <Option
                        key={index}
                        answer_id={component.id}
                        text={component.text}
                        className={component.class_name}
                        sendUserInput={sendUserInput}
                    />
                );
              case 'show_map':
                return (
                    <ShowMap
                        key={index}
                        className={component.class_name}
                        lat={component.lat}
                        lng={component.lng}
                        pointsOfInterest={component.pointsOfInterest}
                        sendGPS={sendGPS}
                    />
                );
              case 'sliding_puzzle':
                return (
                    <SlidingPuzzleComponent
                        key={index}
                        // className={component.class_name}
                        sendUserInput={sendUserInput}
                    />
                );
              case 'ferry_game':
                return (
                    <FerryGameComponent
                        key={index}
                        // className={component.class_name}
                        sendUserInput={sendUserInput}
                    />
                );
              default:
                return null;
            }
          })}
        </div>

        {debugMode && roomListVisible && (
            <div className="bg-gray-100 p-4 rounded mt-4 overflow-x-auto max-w-full">
              <h3 className="text-lg font-semibold">Debug Room List</h3>
              <ul className="list-disc list-inside">
                {roomList.map((room, index) => (
                    <li
                        key={index}
                        className="cursor-pointer hover:bg-gray-200 p-2"
                        onClick={() => {
                          navigate(`/game/${gameId}/${room}`);
                          window.location.reload();
                        }}
                    >
                      {room}
                    </li>
                ))}
              </ul>
            </div>
        )}
      </div>
    </DebugProvider>
  );
};

export default Game;
