import React from 'react';
import { useDebugMode } from '../DebugContext';

const HighlightElement = ({ text, className, showDocs = false }) => {
  const isDebugMode = useDebugMode(); // Zjistíme, zda je zapnutý debug mód

  // Pokud jsme v debug módu a showDocs je true, zobrazíme pouze dokumentaci
  if (isDebugMode && showDocs) {
    return (
      <div className="mt-4 p-4 bg-gray-50 border border-gray-300 rounded-lg">
        <p>
          <strong>HighlightElement</strong> se používá k zvýraznění důležitého textu, který je vizuálně oddělen pomocí výrazné barvy pozadí a stylizace textu.
          Tento prvek je užitečný pro upozornění hráče na důležité informace nebo akce.
        </p>
        <div className="bg-yellow-200 text-black font-semibold px-4 py-2 rounded text-center mx-auto">
          Toto je zvýrazněný text.
        </div>
        <pre className="bg-gray-100 p-4 rounded mt-4 overflow-x-auto max-w-full">
{`<HighlightElement text="Toto je zvýrazněný text." />`}
        </pre>
      </div>
    );
  }

  // Normální funkční komponenta
  return (
    <div className={`bg-yellow-200 text-black font-semibold px-4 py-2 rounded text-center mx-auto ${className}`}>
      <p>{text}</p>
    </div>
  );
};

export default HighlightElement;
