import React, { useState } from 'react';
import victoryImage from '../images/victory-trophy.png';
import errorImage from '../images/error-icon.png'; // Přidejte cestu k obrázku chyby

const OBJECTS = {
  ec: <span className="token-style">🦊</span>, // Ekonomický (fox)
  sa: <span className="token-style">🐐</span>, // Saze (goat)
  cs: <span className="token-style">🥬</span>, // Cabbage (cabbage)
  ferry: <span className="token-style">🚣</span> // Převozník (ferry)
};

const initialStartPosition = ['ec', 'sa', 'cs', 'ferry'];
const transportIcon = "⛴️"; // Ikona převozu

const FerryGameComponent = ({ className, sendUserInput }) => {
  const [startPosition, setStartPosition] = useState(initialStartPosition);
  const [destinationPosition, setDestinationPosition] = useState([]);
  const [showVictoryOverlay, setShowVictoryOverlay] = useState(false);
  const [showErrorOverlay, setShowErrorOverlay] = useState(false); // Stav pro chybový obrázek
  const [middleColumns, setMiddleColumns] = useState(Array.from({ length: 10 }, () => []));
  const [hiddenDuringAnimation, setHiddenDuringAnimation] = useState([]);

  const resetGame = () => {
    setStartPosition(initialStartPosition);
    setDestinationPosition([]);
    setShowVictoryOverlay(false);
    setShowErrorOverlay(false); // Skryjeme chybový obrázek při resetu
    setMiddleColumns(Array.from({ length: 10 }, () => [])); // Obnoví prázdné mezisloupce
    setHiddenDuringAnimation([]); // Zobrazí všechny ikony
  };

  const isValidState = (position) => {
    if (position.includes('ec') && position.includes('sa') && !position.includes('cs')) return false;
    if (position.includes('sa') && position.includes('cs') && !position.includes('ec')) return false;
    return true;
  };

  const checkGameStatus = (newStart, newDestination, movingFrom) => {
    if (newDestination.length === 4) {
      return "win";
    }

    const leftBehind = movingFrom === "start" ? newStart : newDestination;
    if (!isValidState(leftBehind)) {
      return "invalid";
    }

    return "valid";
  };

  const renderCurrentState = (newStart, newDestination) => {
    setStartPosition(newStart);
    setDestinationPosition(newDestination);
    setHiddenDuringAnimation([]);
  };

  const handleMove = (obj) => {
    const oldDestination = [...destinationPosition];
    let newStart = [...startPosition];
    let newDestination = [...destinationPosition];
    let movingFrom = startPosition.includes("ferry") ? "start" : "destination";

    if (obj === 'ferry') {
      if (startPosition.includes('ferry')) {
        newStart = newStart.filter(o => o !== 'ferry');
        newDestination.push('ferry');
      } else {
        newDestination = newDestination.filter(o => o !== 'ferry');
        newStart.push('ferry');
      }
    } else if (startPosition.includes('ferry') && startPosition.includes(obj)) {
      newStart = newStart.filter(o => o !== obj && o !== 'ferry');
      newDestination = [...newDestination, obj, 'ferry'];
    } else if (destinationPosition.includes('ferry') && destinationPosition.includes(obj)) {
      newDestination = newDestination.filter(o => o !== obj && o !== 'ferry');
      newStart = [...newStart, obj, 'ferry'];
    } else {
      alert("Převozník musí být na stejném břehu jako objekt!");
      return;
    }

    setHiddenDuringAnimation([obj, 'ferry']); // Skrytí ikon během animace

    const status = checkGameStatus(newStart, newDestination, movingFrom);
    if (status === "valid") {
      animationMoving(oldDestination, newDestination, movingFrom, obj, () => renderCurrentState(newStart, newDestination));
    } else if (status === "win") {
      animationMoving(oldDestination, newDestination, movingFrom, obj, () => {
        setShowVictoryOverlay(true);
        setTimeout(() => {
          if (sendUserInput) {
            sendUserInput({ user_input: "next" });
          }
          setTimeout(resetGame, 4000); // Obnoví hru po 4 sekundách
        }, 4000); // Zobrazuje overlay po dobu 4 sekund
      });
    } else if (status === "invalid") {
      animationMoving(oldDestination, newDestination, movingFrom, obj, () => {
        setShowErrorOverlay(true);
        setTimeout(resetGame, 2000); // Zobrazí chybový obrázek po dobu 2 sekund a pak resetuje hru
      });
    }
  };

  const animationMoving = (oldDestination, newDestination, movingFrom, obj, onComplete) => {
    const animate = async () => {
      const updatedColumns = [...middleColumns];
      const animateIcon = obj === "ferry" ? OBJECTS.ferry : transportIcon;

      const startIndex = movingFrom === "start" ? 0 : updatedColumns.length - 1;
      const endIndex = movingFrom === "start" ? updatedColumns.length : -1;
      const step = movingFrom === "start" ? 1 : -1;

      for (let i = startIndex; i !== endIndex; i += step) {
        updatedColumns[i] = [animateIcon];
        setMiddleColumns([...updatedColumns]);
        await new Promise(resolve => setTimeout(resolve, 200));
        updatedColumns[i] = [];
      }

      setMiddleColumns(Array.from({ length: 10 }, () => [])); // Vyčistí všechny mezisloupce po dokončení
      onComplete();
    };
    animate();
  };

  const isClickable = (obj) => {
    const ferryOnStart = startPosition.includes("ferry");
    return (ferryOnStart && startPosition.includes(obj)) || (!ferryOnStart && destinationPosition.includes(obj));
  };

  return (
    <div className={`relative flex ${className} items-center justify-center h-[250px] ferry-game-container`}>
      {/* Start sloupec */}
      <div className="flex-1 p-4 icon-column">
        {startPosition.map(obj => (
          <div
            key={obj}
            className={`cursor-pointer text-3xl ${isClickable(obj) && !hiddenDuringAnimation.includes(obj) ? "" : "pointer-events-none"}`}
            onClick={() => isClickable(obj) && handleMove(obj)}
          >
            {hiddenDuringAnimation.includes(obj) ? "" : OBJECTS[obj]}
          </div>
        ))}
      </div>

      {/* Střední sloupce (řeka) */}
      <div className="flex-grow h-full flex justify-between items-center">
        {middleColumns.map((column, index) => (
          <div key={index} className="flex-1 h-full flex items-center justify-center p-2">
            {column.map(item => <span key={item} className="text-5xl">{item}</span>)}
          </div>
        ))}
      </div>

      {/* Destination sloupec */}
      <div className="flex-1 p-4 icon-column">
        {destinationPosition.map(obj => (
          <div
            key={obj}
            className={`cursor-pointer text-3xl ${isClickable(obj) && !hiddenDuringAnimation.includes(obj) ? "" : "pointer-events-none"}`}
            onClick={() => isClickable(obj) && handleMove(obj)}
          >
            {hiddenDuringAnimation.includes(obj) ? "" : OBJECTS[obj]}
          </div>
        ))}
      </div>

      {/* Overlay vítězného poháru */}
      {showVictoryOverlay && (
        <div className="absolute inset-0 flex items-center justify-center bg-white bg-opacity-90">
          <img src={victoryImage} alt="Victory!" className="max-h-full max-w-full" />
        </div>
      )}

      {/* Overlay chybového obrázku */}
      {showErrorOverlay && (
        <div className="absolute inset-0 flex items-center justify-center bg-white bg-opacity-90">
          <img src={errorImage} alt="Error!" className="max-h-full max-w-full" />
        </div>
      )}
    </div>
  );
};

export default FerryGameComponent;
