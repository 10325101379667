class SlidingPuzzle {
  constructor(size = 4) {
    this.size = size;
    this.tiles = [];
    this.emptyIndex = this.size * this.size - 1;
    this.init();
  }

  init() {
    this.tiles = Array.from({ length: this.size * this.size }, (_, i) => (i === this.size * this.size - 1 ? null : i + 1));
    this.emptyIndex = this.tiles.length - 1;
  }

  shuffle(steps = 100) {
    const directions = [-1, 1, -this.size, this.size]; // Left, Right, Up, Down
    for (let i = 0; i < steps; i++) {
      const possibleMoves = directions.filter((dir) => {
        const newIndex = this.emptyIndex + dir;
        if (newIndex < 0 || newIndex >= this.size * this.size) return false;
        if ((dir === 1 || dir === -1) && Math.floor(this.emptyIndex / this.size) !== Math.floor(newIndex / this.size)) return false;
        return true;
      });

      const move = possibleMoves[Math.floor(Math.random() * possibleMoves.length)];
      this.swapTiles(this.emptyIndex + move);
    }
  }

  swapTiles(index) {
    [this.tiles[this.emptyIndex], this.tiles[index]] = [this.tiles[index], this.tiles[this.emptyIndex]];
    this.emptyIndex = index;
  }

  isAdjacent(index1, index2) {
    const row1 = Math.floor(index1 / this.size);
    const col1 = index1 % this.size;
    const row2 = Math.floor(index2 / this.size);
    const col2 = index2 % this.size;
    return (row1 === row2 && Math.abs(col1 - col2) === 1) || (col1 === col2 && Math.abs(row1 - row2) === 1);
  }

  move(index) {
    if (this.isAdjacent(index, this.emptyIndex)) {
      this.swapTiles(index);
      return true;
    }
    return false;
  }

  checkWin() {
    for (let i = 0; i < this.tiles.length - 1; i++) {
      if (this.tiles[i] !== i + 1) {
        return false;
      }
    }
    return true;
  }
}

export default SlidingPuzzle;
