import React from 'react';

const Payment = () => {
  return (
    <div className="max-w-3xl mx-auto p-6 bg-white shadow-md rounded-lg mt-8">
      <h1 className="text-4xl font-bold text-gray-800 mb-6 text-center">Platba</h1>
      <p className="text-lg text-gray-600 mb-6 text-center">Vyberte si způsob platby:</p>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
        {/* Platební brána */}
        <div className="bg-gray-100 p-6 rounded-lg shadow-sm">
          <h3 className="text-2xl font-semibold text-gray-800 mb-4">Platební brána</h3>
          <p className="text-gray-600 mb-4">Použijte kreditní nebo debetní kartu.</p>
          <button className="w-full py-2 px-4 bg-blue-500 text-white rounded-md hover:bg-blue-600">
            Zaplatit kartou
          </button>
        </div>

        {/* Platba převodem */}
        <div className="bg-gray-100 p-6 rounded-lg shadow-sm">
          <h3 className="text-2xl font-semibold text-gray-800 mb-4">Platba převodem</h3>
          <p className="text-gray-600 mb-4">Platba přímo na náš účet pomocí bankovního převodu.</p>
          <button className="w-full py-2 px-4 bg-blue-500 text-white rounded-md hover:bg-blue-600">
            Platba převodem
          </button>
        </div>

        {/* Platba pomocí Bitcoin */}
        <div className="bg-gray-100 p-6 rounded-lg shadow-sm">
          <h3 className="text-2xl font-semibold text-gray-800 mb-4">Platba pomocí Bitcoin</h3>
          <p className="text-gray-600 mb-4">Platba kryptoměnou Bitcoin.</p>
          <button className="w-full py-2 px-4 bg-blue-500 text-white rounded-md hover:bg-blue-600">
            Zaplatit pomocí BTC
          </button>
        </div>

        {/* PayPal */}
        <div className="bg-gray-100 p-6 rounded-lg shadow-sm">
          <h3 className="text-2xl font-semibold text-gray-800 mb-4">PayPal</h3>
          <p className="text-gray-600 mb-4">Platba přes PayPal.</p>
          <button className="w-full py-2 px-4 bg-blue-500 text-white rounded-md hover:bg-blue-600">
            Zaplatit pomocí PayPal
          </button>
        </div>
      </div>
    </div>
  );
};

export default Payment;
