import React from 'react';
import { Link } from 'react-router-dom';

const OrderConfirmation = () => {
  return (
    <div className="max-w-4xl mx-auto p-6 bg-white shadow-md rounded-lg mt-8">
      <h1 className="text-4xl font-bold text-gray-800 mb-4">Děkujeme za vaši objednávku!</h1>
      <p className="text-gray-600 mb-6">
        Vážíme si toho, že jste si vybrali naši hru a rozhodli se s námi vydat na dobrodružství plné zážitků. Právě
        jsme vám odeslali e-mail s podrobnostmi o objednávce a instrukcemi k platbě.
      </p>

      <h2 className="text-3xl font-semibold text-gray-800 mb-4">Podívejte se do své e-mailové schránky</h2>
      <p className="text-gray-600 mb-6">
        Ve vaší e-mailové schránce naleznete zprávu, která obsahuje QR kód pro snadnou platbu a také podrobné údaje o platbě,
        včetně variabilního symbolu a čísla účtu. Tím vám chceme zajistit maximální pohodlí a rychlost při dokončování platby.
      </p>

      <h2 className="text-3xl font-semibold text-gray-800 mb-4">Co následuje po zaplacení</h2>
      <p className="text-gray-600 mb-6">
        Jakmile obdržíme vaši platbu, zašleme vám e-mailem herní kód, který vám umožní přístup k objednané hře.
        Tento kód vám otevře cestu do světa zážitků a hádanek, které na vás čekají. Mějte prosím na paměti, že zpracování
        platby může chvíli trvat.
      </p>

      <h2 className="text-3xl font-semibold text-gray-800 mb-4">Potřebujete pomoc?</h2>
      <p className="text-gray-600 mb-6">
        Pokud máte jakékoli dotazy nebo problémy s vaší objednávkou, neváhejte se na nás obrátit.
        Jsme tady, abychom vám pomohli a zajistili, že budete mít plynulý a příjemný zážitek s našimi hrami.
      </p>

      <h2 className="text-3xl font-semibold text-gray-800 mb-4">Těšíme se na vaše dobrodružství</h2>
      <p className="text-gray-600 mb-6">
        Přejeme vám hodně zábavy a napětí s vaší objednanou hrou. Věříme, že vás pohltí a přinese vám nezapomenutelné chvíle.
        Děkujeme, že jste se stali součástí naší komunity a že s námi objevujete nové světy plné příběhů a záhad!
      </p>

      {/* Tlačítko na hlavní stránku */}
      <div className="text-center mt-8">
        <Link
          to="/"
          className="px-6 py-3 bg-blue-500 text-white font-semibold rounded-lg shadow-md hover:bg-blue-700 transition duration-300"
        >
          Zpět na hlavní stránku
        </Link>
      </div>
    </div>
  );
};

export default OrderConfirmation;
