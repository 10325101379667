import React, { useState, useEffect } from 'react';
import { useDebugMode } from '../DebugContext';

const Option = ({ answer_id, text, className, fadeDuration = 1000, onComplete, sendUserInput, showDocs = false }) => {
  const [isVisible, setIsVisible] = useState(false);
  const isDebugMode = useDebugMode(); // Zjistíme, zda je zapnutý debug mód

  const handleClick = (event) => {
    if (event && event.target) {
      if (sendUserInput) {
        sendUserInput({ user_input: answer_id });
      }
    }
  };

  useEffect(() => {
    setIsVisible(false);

    const timeout = setTimeout(() => {
      setIsVisible(true);
    }, 10);

    return () => clearTimeout(timeout);
  }, [text]);

  const handleTransitionEnd = () => {
    if (onComplete) {
      onComplete();
    }
  };

  // Pokud jsme v debug módu a showDocs je true, zobrazíme pouze dokumentaci
  if (isDebugMode && showDocs) {
    return (
      <div className="mt-4 p-4 bg-gray-50 border border-gray-300 rounded-lg">
        <p>
          <strong>Option</strong> slouží k zobrazení volitelné možnosti, kterou může uživatel vybrat. Komponenta umožňuje kliknutí na volbu, kterou lze odeslat jako vstup.
        </p>
        <div className="option-container cursor-pointer bg-blue-100 text-blue-800 hover:bg-blue-200 font-semibold rounded-lg p-4 shadow-lg my-2 transition-all duration-300 ease-in-out">
          <p className="text-center">Ukázková možnost</p>
        </div>
        <pre className="bg-gray-100 p-4 rounded mt-4 overflow-x-auto max-w-full">
{`<Option answer_id="a" text="Toto je volba" sendUserInput={handleSend} />`}
        </pre>
      </div>
    );
  }

  // Normální funkční komponenta
  return (
    <div
      id={`option_${answer_id}`}
      className={`option-container cursor-pointer bg-blue-100 text-blue-800 hover:bg-blue-200 font-semibold rounded-lg p-4 shadow-lg my-2 transition-all duration-300 ease-in-out ${className}`}
      onClick={handleClick}
      onTransitionEnd={handleTransitionEnd}
    >
      <p className="text-center">{text}</p>
    </div>
  );
};

export default Option;
