import React from 'react';
import { useDebugMode } from '../DebugContext';

const ImageElement = ({ url, alt, className, showDocs = false, size = 100 }) => {
  const isDebugMode = useDebugMode(); // Zjistíme, zda je zapnutý debug mód

  // Pokud jsme v debug módu a showDocs je true, zobrazíme pouze dokumentaci
  if (isDebugMode && showDocs) {
    return (
      <div className="mt-4 p-4 bg-gray-50 border border-gray-300 rounded-lg">
        <p>
          <strong>ImageElement</strong> slouží k zobrazení obrázku s různými vizuálními efekty. Obrázek je responsivní, s efektem zvětšení při najetí kurzoru.
        </p>
        <div className="image-container max-w-full overflow-hidden rounded-lg shadow-md">
          <img
            src="https://via.placeholder.com/600"
            alt="Ukázkový obrázek"
            className="w-full h-auto object-cover transform hover:scale-105 transition-transform duration-300 ease-in-out"
          />
        </div>
        <pre className="bg-gray-100 p-4 rounded mt-4 overflow-x-auto max-w-full">
{`<ImageElement url="https://via.placeholder.com/600" alt="Ukázkový obrázek" />`}
        </pre>
      </div>
    );
  }

  // Normální funkční komponenta
  const style = {
    width: `${size}%`,
    margin: '0 auto',
    textAlign: 'center',
  };

  return (
    <div style={style} className={`image-container ${className} max-w-full overflow-hidden rounded-lg shadow-md`}>
      <img
        src={url}
        alt={alt}
        className="w-full h-auto object-cover transform hover:scale-105 transition-transform duration-300 ease-in-out"
      />
    </div>
  );
};

export default ImageElement;
