import React from 'react';

const About = () => {
  return (
    <div className="max-w-4xl mx-auto p-6 bg-white shadow-md rounded-lg mt-8">
      <h1 className="text-4xl font-bold text-gray-800 mb-4">O naší hře</h1>
      <p className="text-gray-600 mb-6">
        Vítejte na naší platformě pro interaktivní hry! Naším cílem je vytvářet poutavé a podnětné zážitky, které
        vyzvou vaši mysl a podnítí vaši kreativitu. Věříme v sílu příběhů, a proto jsou naše hry navrženy tak, aby
        nabízely jedinečné příběhy, hádanky a dobrodružství, která osloví širokou škálu hráčů.
      </p>

      <h2 className="text-3xl font-semibold text-gray-800 mb-4">Naše vize</h2>
      <p className="text-gray-600 mb-6">
        Usilujeme o tvorbu her, které nejen baví, ale také inspirují hráče k tomu, aby přemýšleli, řešili problémy a
        objevovali nové možnosti. S důrazem na zábavu i vzdělávání jsou naše hry vhodné pro hráče všech věkových
        kategorií a prostředí.
      </p>

      <h2 className="text-3xl font-semibold text-gray-800 mb-4">Co nabízíme</h2>
      <ul className="list-disc list-inside text-gray-600 mb-6 space-y-2">
        <li>Poutavé hádanky a výzvy, které prověří vaše dovednosti</li>
        <li>Imersivní příběhy, které vás vtáhnou do různých světů</li>
        <li>Interaktivní zážitky kombinující kreativitu s řešením problémů</li>
        <li>Podporující komunitu hráčů a tvůrců</li>
      </ul>

      <h2 className="text-3xl font-semibold text-gray-800 mb-4">Přidejte se k nám na naší cestě</h2>
      <p className="text-gray-600">
        Ať už jste tu kvůli hádankám, příběhům, nebo prostě jen kvůli zábavě, zveme vás, abyste se připojili k naší
        cestě objevování a zkoumání. Neustále pracujeme na nových projektech a rozšiřujeme naši platformu, takže
        sledujte naše další dobrodružství!
      </p>
    </div>
  );
};

export default About;
