import React, { useState, useEffect } from 'react';
import { useDebugMode } from '../DebugContext';

const TimerTextElement = ({ initialTime, className, showDocs = false }) => {
  const [time, setTime] = useState(initialTime);
  const isDebugMode = useDebugMode(); // Zjistíme, zda je zapnutý debug mód

  useEffect(() => {
    const timer = setInterval(() => {
      setTime((prevTime) => (prevTime > 0 ? prevTime - 1 : 0));
    }, 1000);
    return () => clearInterval(timer);
  }, []);

  // Pokud jsme v debug módu a showDocs je true, zobrazíme pouze dokumentaci
  if (isDebugMode && showDocs) {
    return (
      <div className="mt-4 p-4 bg-gray-50 border border-gray-300 rounded-lg">
        <p>
          <strong>TimerTextElement</strong> slouží k zobrazení odpočítávání času. Tento prvek je ideální pro zobrazení zbývajícího času v hrách nebo aplikacích.
        </p>
        <div className="text-3xl text-red-600 font-bold text-center my-4">
          Zbývá 10 sekund
        </div>
        <pre className="bg-gray-100 p-4 rounded mt-4 overflow-x-auto max-w-full">
{`<TimerTextElement initialTime={10} />`}
        </pre>
      </div>
    );
  }

  // Normální funkční komponenta
  return (
    <div className={`text-3xl text-red-600 font-bold text-center my-4 ${className}`}>
      <p>Zbývá {time} sekund</p>
    </div>
  );
};

export default TimerTextElement;
