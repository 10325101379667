import React from 'react';
import { useDebugMode } from '..//DebugContext';

const TextElement = ({ text, className, showDocs = false }) => {
  const isDebugMode = useDebugMode(); // Zjistíme, zda je zapnutý debug mód

  // Pokud jsme v debug módu a showDocs je true, zobrazíme pouze dokumentaci
  if (isDebugMode && showDocs) {
    return (
      <div className="mt-4 p-4 bg-gray-50 border border-gray-300 rounded-lg">
        <p>
          <strong>TextElement</strong> slouží k zobrazení obyčejného textu. Tento prvek může být použit pro různé textové bloky a je stylizován s jednoduchým černým textem.
        </p>
        <div className="text-black text-left my-4">
          Toto je příklad obyčejného textu.
        </div>
        <pre className="bg-gray-100 p-4 rounded mt-4 overflow-x-auto max-w-full">
{`<TextElement text="Toto je příklad obyčejného textu." />`}
        </pre>
      </div>
    );
  }

  // Normální funkční komponenta
  return (
    <div className={`text-black text-left my-4 ${className}`}>
      <p>{text}</p>
    </div>
  );
};

export default TextElement;
