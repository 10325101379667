import React from 'react';
import { useDebugMode } from '../DebugContext';

const FailureElement = ({ text, className, showDocs = false }) => {
  const isDebugMode = useDebugMode(); // Zjistíme, zda je zapnutý debug mód

  // Pokud jsme v debug módu a showDocs je true, zobrazíme pouze dokumentaci
  if (isDebugMode && showDocs) {
    return (
      <div className="mt-4 p-4 bg-gray-50 border border-gray-300 rounded-lg">
        <p>
          <strong>FailureElement</strong> se používá pro zobrazení chybové nebo varovné zprávy, která indikuje neúspěch. Tato komponenta používá animaci pro dramatický efekt.
        </p>
        <div className="relative text-white bg-red-600 border-2 border-red-800 px-4 py-2 rounded-lg shadow-2xl text-center font-bold uppercase tracking-wide animate-shake">
          <p className="text-lg">Něco je špatně!</p>
          <div className="absolute inset-0 border-4 border-red-900 rounded-lg opacity-70"></div>
        </div>
        <pre className="bg-gray-100 p-4 rounded mt-4 overflow-x-auto max-w-full">
{`<FailureElement text="Něco je špatně!" />`}
        </pre>
      </div>
    );
  }

  // Normální funkční komponenta
  return (
    <div className="mb-4">
      <div className={`relative text-white bg-red-600 border-2 border-red-800 px-4 py-2 rounded-lg shadow-2xl text-center font-bold uppercase tracking-wide animate-shake ${className}`}>
        <p className="text-lg">{text}</p>
        <div className="absolute inset-0 border-4 border-red-900 rounded-lg opacity-70"></div>
      </div>
    </div>
  );
};

export default FailureElement;
