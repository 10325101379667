import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

const Home = () => {
  const [games, setGames] = useState([]);
  const navigate = useNavigate();

const handleOrderClick = (game) => {
    navigate("/order", { state: { game } });
  };

  useEffect(() => {
    const fetchGames = async () => {
      try {
        const response = await fetch('/games-list');
        const data = await response.json();
        setGames(data);
      } catch (error) {
        console.error("Failed to fetch games:", error);
      }
    };

    fetchGames();
  }, []);

  return (
      <div className="max-w-7xl mx-auto p-6">
          <h1 className="text-4xl font-bold text-gray-800 mb-8 text-center">Vyberte si hru</h1>

          {/* Popis tlačítka "Hrát" */}
          <div className="mb-6 text-center text-gray-700 text-lg">
              Máte už zakoupenou hru a připravený herní kód? Klikněte na tlačítko níže a můžete hned začít!
          </div>

          {/* Výrazné tlačítko "Hrát" */}
          <div className="flex justify-center mb-10">
              <Link
                  to="/codeentry"
                  className="px-10 py-5 text-3xl bg-green-600 text-white font-bold rounded-lg shadow-lg hover:bg-green-700 transition-all duration-300 ease-in-out transform hover:scale-105"
              >
                  Hrát
              </Link>
          </div>

          {/* Popis pro seznam her */}
          <div className="mb-6 text-center text-gray-700 text-lg">
              Nemáte ještě hru, nebo jste už nějakou dohráli a chcete si zahrát další? Podívejte se na naši nabídku níže
              a vyberte si to pravé dobrodružství pro vás!
          </div>

          {/* Seznam her */}
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
              {games.map((game, index) => (
                  <div key={index} className="game-card bg-white shadow-md rounded-lg overflow-hidden">
                      <img src={game.image || '/placeholder.jpg'} alt={`Hra ${index + 1}`}
                           className="w-full h-48 object-cover"/>
                      <div className="p-6">
                          <h2 className="text-2xl font-bold text-gray-800 mb-2">{game.title}</h2>
                          <p className="text-gray-600 mb-4">{game.description}</p>

                          {/* Cena hry */}
                          <p className="text-gray-800 font-semibold mb-4">
                              Cena: {game.price ? `${game.price} Kč` : 'Cena není dostupná'}
                          </p>

                          <div className="flex space-x-4">
                              <button
                                  onClick={() => handleOrderClick(game)}
                                  className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600"
                              >
                                  Objednat
                              </button>
                          </div>
                      </div>
                  </div>
              ))}
          </div>
      </div>
  );
};

export default Home;
