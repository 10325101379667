import React from 'react';
import { useDebugMode } from '../DebugContext';

const CodeElement = ({ text, className, showDocs = false }) => {
  const isDebugMode = useDebugMode(); // Zjistíme, zda je zapnutý debug mód

  // Pokud jsme v debug módu a showDocs je true, zobrazíme pouze dokumentaci
  if (isDebugMode && showDocs) {
    return (
      <div className="mt-4 p-4 bg-gray-50 border border-gray-300 rounded-lg">
        <p>
          <strong>CodeElement</strong> slouží k zobrazování kódu s monospace fontem. Tato komponenta je užitečná pro zobrazení kódu nebo technických informací ve hře či aplikaci.
        </p>
        <div className="bg-gray-100 text-gray-900 font-mono p-4 rounded">
          <pre><code>Toto je ukázkový kód.</code></pre>
        </div>
        <pre className="bg-gray-100 p-4 rounded mt-4 overflow-x-auto max-w-full">
{`<CodeElement text="Toto je ukázkový kód." />`}
        </pre>
      </div>
    );
  }

  // Normální funkční komponenta
  return (
    <div className={`font-mono text-gray-900 bg-gray-100 p-2 rounded text-left my-4 ${className}`}>
      <pre><code>{text}</code></pre>
    </div>
  );
};

export default CodeElement;
