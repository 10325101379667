import React, { useEffect, useRef, useState } from 'react';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import 'leaflet.locatecontrol/dist/L.Control.Locate.min.css';
import 'leaflet.locatecontrol';

const ShowMap = ({ className, lat = 49.47234986156489, lng = 17.109858574943676, pointsOfInterest = [], onComplete, sendGPS, showDocs = false }) => {
  const mapRef = useRef(null);
  const mapContainerRef = useRef(null);
  const [showPOIList, setShowPOIList] = useState(false);
  const hasCompleted = useRef(false);
  const currentPositionMarker = useRef(null);
  const permissionRequested = useRef(false);
  const lastSentGPS = useRef(null);
  const sendInterval = 5000;

const togglePOIList = () => {
    setShowPOIList(prevState => !prevState);
  };

  // Přidání event listeneru pro klávesovou zkratku
  useEffect(() => {
    const handleKeydown = (event) => {
      if (event.ctrlKey && event.key === 'd') {
        togglePOIList();
      }
    };
    window.addEventListener('keydown', handleKeydown);
    return () => window.removeEventListener('keydown', handleKeydown);
  }, []);

  const handlePOIClick = (poi) => {
    if (sendGPS) {
      sendGPS({ lat: poi.lat, lng: poi.lng });
    }
    setShowPOIList(false); // Skryje box po výběru POI
  };

  const isValidLatLng = (lat, lng) => {
    return (
      typeof lat === 'number' && typeof lng === 'number' &&
      !isNaN(lat) && !isNaN(lng) &&
      lat >= -90 && lat <= 90 && lng >= -180 && lng <= 180
    );
  };

  const requestGeolocationPermission = () => {
if ('geolocation' in navigator) {
    navigator.geolocation.getCurrentPosition(
        (position) => {
            console.log('Poloha získána:', position);
            localStorage.setItem('gpsPermissionGranted', 'true');
        },
        (error) => {
            console.error('Chyba při získávání polohy:', error);
            alert('Musíte povolit přístup k poloze, aby mapa fungovala.');
        },
{
          enableHighAccuracy: true,
          timeout: 10000,
          maximumAge: 0
        }
      );
    } else {
      alert('Vaše zařízení nepodporuje GPS nebo není povolen přístup k poloze.');
    }
  };

  const confirmGeolocationAccess = () => {
      if (!permissionRequested.current && !localStorage.getItem('gpsPermissionGranted')) {
        permissionRequested.current = true;
        const userConfirmed = window.confirm(
          'Tato aplikace potřebuje přístup k vaší poloze pro správné fungování. Chcete pokračovat a povolit přístup?'
        );

        if (userConfirmed) {
          requestGeolocationPermission();
        } else {
          alert('Přístup k poloze nebyl povolen.');
        }
      }
  };

  useEffect(() => {
    if (showDocs) {
      return;
    }

    if (mapRef.current !== null) {
      console.log("Map is already initialized, skipping re-initialization");
      return;
    }

    console.log("Initializing map...");
    mapRef.current = L.map(mapContainerRef.current).setView([lat, lng], 13);

    L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      maxZoom: 19,
      attribution: '© OpenStreetMap'
    }).addTo(mapRef.current);

    const locateControl = L.control.locate({
      position: 'topright',
      setView: true,
      keepCurrentZoomLevel: true,
      flyTo: true,
      drawCircle: false,
      icon: 'leaflet-control-locate-location-arrow',
      strings: {
        title: "Lokalizuj mě",
      },
    }).addTo(mapRef.current);

    locateControl.start();

    confirmGeolocationAccess();

    if (mapRef.current) {
        mapRef.current.invalidateSize();
    }

    const customIcon = L.icon({
      iconUrl: '/icons/poi.png',
      iconSize: [48, 48],
      iconAnchor: [24, 48],
      popupAnchor: [0, -48]
    });

    if (pointsOfInterest && pointsOfInterest.length > 0) {
      pointsOfInterest.forEach(poi => {
        if (isValidLatLng(poi.lat, poi.lng)) {
          L.marker([poi.lat, poi.lng], { icon: customIcon })
            .addTo(mapRef.current)
            .bindPopup(`<b>${poi.description}</b>`);
        }
      });
    }

	class KalmanFilter {
	  constructor(dt) {
		this.dt = dt;
		this.isInitialized = false;
		this.x = [[0], [0], [0], [0]];
		this.P = [[1, 0, 0, 0], [0, 1, 0, 0], [0, 0, 1, 0], [0, 0, 0, 1]];
		this.F = [
		  [1, this.dt, 0, 0],
		  [0, 1, 0, 0],
		  [0, 0, 1, this.dt],
		  [0, 0, 0, 1]
		];
		this.H = [
		  [1, 0, 0, 0],
		  [0, 0, 1, 0]
		];
		this.R = [[0.5, 0], [0, 0.5]];
		this.Q = [
		  [0.1, 0, 0, 0],
		  [0, 0.1, 0, 0],
		  [0, 0, 0.1, 0],
		  [0, 0, 0, 0.1]
		];
	  }

	  predict() {
		this.x = this.matrixMultiply(this.F, this.x);
		const Ft = this.transpose(this.F);
		this.P = this.matrixAdd(this.matrixMultiply(this.matrixMultiply(this.F, this.P), Ft), this.Q);
	  }

	  update(z) {
		if (!this.isInitialized) {
		  this.x[0][0] = z[0][0];
		  this.x[2][0] = z[1][0];
		  this.isInitialized = true;
		}

		const y = this.matrixSubtract(z, this.matrixMultiply(this.H, this.x));
		const Ht = this.transpose(this.H);
		const S = this.matrixAdd(this.matrixMultiply(this.matrixMultiply(this.H, this.P), Ht), this.R);
		const K = this.matrixMultiply(this.matrixMultiply(this.P, Ht), this.inverse(S));
		this.x = this.matrixAdd(this.x, this.matrixMultiply(K, y));
		const I = [
		  [1, 0, 0, 0],
		  [0, 1, 0, 0],
		  [0, 0, 1, 0],
		  [0, 0, 0, 1]
		];
		this.P = this.matrixMultiply(this.matrixSubtract(I, this.matrixMultiply(K, this.H)), this.P);
	  }

	  getPosition() {
		return {
		  latitude: this.x[0][0],
		  longitude: this.x[2][0]
		};
	  }

	  getVelocity() {
		return {
		  velocityX: this.x[1][0],
		  velocityY: this.x[3][0]
		};
	  }

	  // Helper methods for matrix operations
	  matrixMultiply(a, b) {
		const aNumRows = a.length, aNumCols = a[0].length,
			  bNumRows = b.length, bNumCols = b[0].length,
			  m = new Array(aNumRows);
		for (let r = 0; r < aNumRows; ++r) {
		  m[r] = new Array(bNumCols);
		  for (let c = 0; c < bNumCols; ++c) {
			m[r][c] = 0;
			for (let i = 0; i < aNumCols; ++i) {
			  m[r][c] += a[r][i] * b[i][c];
			}
		  }
		}
		return m;
	  }

	  matrixAdd(a, b) {
		const aNumRows = a.length, aNumCols = a[0].length,
			  m = new Array(aNumRows);
		for (let r = 0; r < aNumRows; ++r) {
		  m[r] = new Array(aNumCols);
		  for (let c = 0; c < aNumCols; ++c) {
			m[r][c] = a[r][c] + b[r][c];
		  }
		}
		return m;
	  }

	  matrixSubtract(a, b) {
		const aNumRows = a.length, aNumCols = a[0].length,
			  m = new Array(aNumRows);
		for (let r = 0; r < aNumRows; ++r) {
		  m[r] = new Array(aNumCols);
		  for (let c = 0; c < aNumCols; ++c) {
			m[r][c] = a[r][c] - b[r][c];
		  }
		}
		return m;
	  }

      transpose(matrix) {
        return matrix[0].map((col, i) => matrix.map(row => row[i]));
      }

      inverse(matrix) {
        const m = matrix.length, n = matrix[0].length;
        const I = [], C = [];
        for (let i = 0; i < m; i++) {
          I[I.length] = [];
          C[C.length] = [];
          for (let j = 0; j < n; j++) {
            I[i][j] = i === j ? 1 : 0;
            C[i][j] = matrix[i][j];
          }
        }
        for (let i = 0; i < m; i++) {
          let e = C[i][i];
          for (let j = 0; j < n; j++) {
            C[i][j] /= e;
            I[i][j] /= e;
          }
          for (let k = 0; k < m; k++) {
            if (k !== i) {
              e = C[k][i];
              for (let j = 0; j < n; j++) {
                C[k][j] -= e * C[i][j];
                I[k][j] -= e * I[i][j];
              }
            }
          }
        }
        return I;
      }
    }

    const kalmanFilter = new KalmanFilter(1);

    if ('geolocation' in navigator) {
      // Sledování polohy
      navigator.geolocation.watchPosition(
        (position) => {
          const latlng = {
            lat: position.coords.latitude,
            lng: position.coords.longitude
          };

          // Kontrola validity souřadnic
          if (isValidLatLng(latlng.lat, latlng.lng)) {
            const z = [[latlng.lat], [latlng.lng]];

            kalmanFilter.predict();
            kalmanFilter.update(z);
            const filteredPosition = kalmanFilter.getPosition();

            const filteredLatLng = {
              lat: filteredPosition.latitude,
              lng: filteredPosition.longitude
            };

            if (currentPositionMarker.current) {
              // Update marker position
              currentPositionMarker.current.setLatLng(filteredLatLng);
            }

            const now = Date.now();
            if (!lastSentGPS.current || now - lastSentGPS.current >= sendInterval) {
              lastSentGPS.current = now;
              if (sendGPS) {
                sendGPS({ lat: filteredLatLng.lat, lng: filteredLatLng.lng });
              }
            }
          } else {
            console.error('Invalid LatLng object:', latlng);
          }
        },
        (error) => {
          console.error('Error watching position:', error);
        },
        {
          enableHighAccuracy: true,
          timeout: 10000,
          maximumAge: 0
        }
      );
    } else {
      alert("GPS není dostupná na tomto zařízení.");
    }

    if (!hasCompleted.current && onComplete) {
      onComplete();
      hasCompleted.current = true;
    }

    const handleResize = () => {
      if (mapRef.current) {
        mapRef.current.invalidateSize();
      }
    };
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
      if (mapRef.current !== null) {
        // mapRef.current.remove();
        // mapRef.current = null;
      }
    };
  }, [sendGPS, showDocs]);

  if (showDocs) {
      // Dokumentační část
      return (
        <div className="mt-4 p-4 bg-gray-50 border border-gray-300 rounded-lg">
          <h4 className="font-semibold mb-2">Dokumentace:</h4>
          <p>
            <strong>ShowMap</strong> komponenta slouží k zobrazení interaktivní mapy s body zájmu (POI). Používá knihovnu Leaflet pro vykreslení mapy a nabízí sledování polohy uživatele.
          </p>
          <div className="map-snapshot bg-gray-200 text-center text-lg font-bold text-gray-700 py-12 rounded-lg">
            Statický náhled mapy
          </div>
          <pre className="bg-gray-100 p-4 rounded mt-4 overflow-x-auto max-w-full">
    {`<ShowMap lat={49.4723} lng={17.1098} pointsOfInterest={[{ lat: 49.47234986156489, lng: 17.109858574943676, description: 'Bod zájmu' }]} />`}
          </pre>
        </div>
      );
  }

  return (
    <div>
      {/* Box pro zobrazení POI, skrytý pomocí zkratky */}
      {showPOIList && (
        <div className="bg-gray-100 p-4 rounded-lg shadow-md mb-4">
          <h2 className="text-xl font-bold text-gray-800 mb-2">Seznam POI</h2>
          <ul className="space-y-2">
            {pointsOfInterest.map((poi, index) => (
              <li
                key={index}
                onClick={() => handlePOIClick(poi)}
                className="cursor-pointer text-blue-600 hover:underline"
              >
                {poi.description} ({poi.lat}, {poi.lng})
              </li>
            ))}
           </ul>
        </div>
      )}
       <div
        ref={mapContainerRef}
        id="map"
        className={`${className} w-full h-96 border-2 border-black rounded-lg`}
      >
        {/* Mapa se vykreslí zde */}
      </div>
      </div>
  );
};

export default ShowMap;
