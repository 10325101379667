import React, { useEffect, useState } from "react";
import ShowMap from "../components/ShowMap";

const generateRandomPOIs = (lat, lng, distance = 250) => {
  const randomOffset = () => (Math.random() * 2 - 1) * (distance / 111320); // Přibližný přepočet na metry

  return [
    {
      lat: lat + randomOffset(),
      lng: lng + randomOffset(),
      description: "Náhodný bod zájmu 1",
    },
    {
      lat: lat + randomOffset(),
      lng: lng + randomOffset(),
      description: "Náhodný bod zájmu 2",
    },
  ];
};

const TestDevice = () => {
  const [userLocation, setUserLocation] = useState(null);
  const [POIs, setPOIs] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setUserLocation({ lat: latitude, lng: longitude });
          setPOIs(generateRandomPOIs(latitude, longitude));
        },
        (err) => {
          console.error("Chyba při získávání polohy:", err);
          setError(
            "Nepodařilo se získat vaši aktuální polohu. Zkontrolujte nastavení GPS."
          );
        },
        { enableHighAccuracy: true, timeout: 10000, maximumAge: 0 }
      );
    } else {
      setError("Vaše zařízení nepodporuje GPS nebo je zakázán přístup.");
    }
  }, []);

  return (
    <div className="max-w-4xl mx-auto p-6 bg-white shadow-md rounded-lg mt-8">
      <h1 className="text-4xl font-bold text-gray-800 mb-4">Test vašeho zařízení</h1>
      {error ? (
        <div className="w-full p-4 bg-red-100 text-red-800 border border-red-400 rounded">
          <p>{error}</p>
        </div>
      ) : !userLocation ? (
        <div className="w-full p-4 bg-gray-100 text-gray-800 border border-gray-300 rounded">
          <p>Získávám vaši polohu...</p>
        </div>
      ) : (
        <>
          <p className="text-gray-600 mb-6">
            Na této obrazovce se zobrazuje mapa s vaší aktuální polohou a dvěma náhodnými body
            zájmu v okolí. Pokud vše funguje správně, je vaše zařízení vhodné pro použití
            aplikace.
          </p>
          <ShowMap
            className="h-96 border border-gray-300 rounded-lg"
            lat={userLocation.lat}
            lng={userLocation.lng}
            pointsOfInterest={POIs}
            sendGPS={(data) => console.log("Odesílám GPS data:", data)}
          />
          <ul className="list-disc list-inside text-gray-600 mt-6 space-y-2">
            <li>
              Aktuální poloha: {userLocation.lat.toFixed(5)}, {userLocation.lng.toFixed(5)}
            </li>
            {POIs.map((poi, index) => (
              <li key={index}>
                {poi.description}: {poi.lat.toFixed(5)}, {poi.lng.toFixed(5)}
              </li>
            ))}
          </ul>
        </>
      )}
    </div>
  );
};

export default TestDevice;
