import React, { useEffect, useState } from 'react';
import SlidingPuzzle from './lib/SlidingPuzzle';
import { useDebugMode } from '../DebugContext';

const SlidingPuzzleComponent = ({ className, onComplete, puzzleId, sendUserInput, showDocs = false }) => {
  const [puzzle] = useState(new SlidingPuzzle());
  const [tiles, setTiles] = useState(puzzle.tiles);
  const [hasShuffled, setHasShuffled] = useState(false);
  const isDebugMode = useDebugMode(); // Zjistíme, zda je zapnutý debug mód

  useEffect(() => {
    if (!hasShuffled && !showDocs) { // Abychom nemíchali puzzle v dokumentačním módu
      console.log(`Zobrazuji puzzle ${puzzleId} a míchám je`);
      puzzle.shuffle();
      setTiles([...puzzle.tiles]);
      setHasShuffled(true);

      setTimeout(() => {
        console.log(`Puzzle ${puzzleId} zobrazeno a zamícháno, volám onComplete`);
        if (onComplete) {
          onComplete(puzzleId);
        }
      }, 500);
    }
  }, [hasShuffled, puzzle, onComplete, puzzleId, showDocs]);

  const handleTileClick = (index) => {
    const emptyIndex = puzzle.tiles.findIndex(tile => tile === null);
    const row = Math.floor(index / puzzle.size);
    const col = index % puzzle.size;
    const emptyRow = Math.floor(emptyIndex / puzzle.size);
    const emptyCol = emptyIndex % puzzle.size;

    const isNeighbor = (Math.abs(row - emptyRow) + Math.abs(col - emptyCol)) === 1;

    if (isNeighbor) {
      puzzle.swapTiles(index);
      setTiles([...puzzle.tiles]);

      setTimeout(() => {
        if (puzzle.checkWin()) {
          console.log(`Puzzle ${puzzleId} completed! Sending user input to server.`);
          if (sendUserInput) {
            sendUserInput({ user_input: "next" });  // Odeslání zprávy na server
          }
        }
      }, 100);
    }
  };

  // Pokud jsme v debug módu a showDocs je true, zobrazíme pouze dokumentaci
  if (isDebugMode && showDocs) {
    const staticPuzzle = [1, 2, 3, 4, 5, 6, 7, 8, null, 9, 10, 11, 12, 13, 14, 15]; // Ukázkový statický puzzle

    return (
      <div className="mt-4 p-4 bg-gray-50 border border-gray-300 rounded-lg">
        <p>
          <strong>SlidingPuzzleComponent</strong> je interaktivní puzzle hra, která zobrazuje dlaždice v mřížce a umožňuje hráčům přesouvat dlaždice, aby složili puzzle. Hra se zamíchá při načtení a po dokončení upozorňuje server.
        </p>
        <div className="puzzle-container grid grid-cols-4 gap-2 w-full h-full" style={{ maxHeight: 'calc(100vh - 100px)', maxWidth: '100%' }}>
          {staticPuzzle.map((tile, index) => (
            <div
              key={index}
              className={`grid-item relative bg-gray-200 rounded-lg shadow-md ${tile === null ? 'bg-transparent' : 'bg-gray-300'}`}
              style={{ paddingBottom: '100%' }}
            >
              <div className="absolute inset-0 flex justify-center items-center text-2xl font-bold text-gray-800">
                {tile}
              </div>
            </div>
          ))}
        </div>
        <pre className="bg-gray-100 p-4 rounded mt-4 overflow-x-auto max-w-full">
{`<SlidingPuzzleComponent puzzleId="1" onComplete={handleComplete} sendUserInput={handleUserInput} />`}
        </pre>
      </div>
    );
  }

  // Normální funkční komponenta
  return (
    <div className={`puzzle-container ${className} grid grid-cols-4 gap-2 w-full h-full`} style={{ maxHeight: 'calc(100vh - 100px)', maxWidth: '100%' }}>
      {tiles.map((tile, index) => (
        <div
          key={index}
          className={`grid-item relative bg-gray-200 rounded-lg shadow-md cursor-pointer ${tile === null ? 'bg-transparent' : 'bg-gray-300'}`}
          onClick={() => handleTileClick(index)}
          style={{ paddingBottom: '100%' }}
        >
          <div className="absolute inset-0 flex justify-center items-center text-2xl font-bold text-gray-800">
            {tile}
          </div>
        </div>
      ))}
    </div>
  );
};

export default SlidingPuzzleComponent;
