import React from 'react';

const Leaderboard = () => {
  // Testovací data, která mohou být později nahrazena dynamickými daty
  const leaderboardData = [
    { nickname: 'Player1', time: '12:34', score: 1500 },
    { nickname: 'Player2', time: '14:20', score: 1300 },
    { nickname: 'Player3', time: '15:50', score: 1200 },
    { nickname: 'Player4', time: '16:10', score: 1100 },
  ];

  return (
    <div className="max-w-4xl mx-auto p-6 bg-white shadow-md rounded-lg mt-8">
      <h1 className="text-4xl font-bold text-gray-800 mb-6">Leaderboard</h1>
      <div className="overflow-x-auto">
        <table className="min-w-full text-left table-auto">
          <thead>
            <tr className="bg-gray-200">
              <th className="px-4 py-2">Přezdívka</th>
              <th className="px-4 py-2">Čas</th>
              <th className="px-4 py-2">Body</th>
            </tr>
          </thead>
          <tbody>
            {leaderboardData.map((player, index) => (
              <tr key={index} className="border-b">
                <td className="px-4 py-2">{player.nickname}</td>
                <td className="px-4 py-2">{player.time}</td>
                <td className="px-4 py-2">{player.score}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Leaderboard;
